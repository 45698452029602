// Signup
export const SIGN_UP_START = "SIGN_UP_START";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_FAILURE = "SIGN_UP_FAILURE";

// Authentication
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const RESET_PASSWORD_LINK = "RESET_PASSWORD_LINK";
export const CLEAR_RESET_RESPONSE = "CLEAR_RESET_RESPONSE";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const PENDING_REGISTRATION = "PENDING_REGISTRATION";
export const RESET_FIRST_LOGIN = "RESET_FIRST_LOGIN";

// Login User
export const LOGIN_USER_LOADING = "LOGIN_USER_LOADING";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";

//get projects
export const GET_PROJECT_LIST_START = "GET_PROJECT_LIST_START";
export const GET_PROJECT_LIST_FAIL = "GET_PROJECT_LIST_FAIL";
export const GET_PROJECT_LIST_SUCCESS = "GET_PROJECT_LIST_SUCCESS";

//get project details
export const GET_PROJECT_DETAIL_START = "GET_PROJECT_DETAIL_START";
export const GET_PROJECT_DETAIL_FAIL = "GET_PROJECT_DETAIL_FAIL";
export const GET_PROJECT_DETAIL_SUCCESS = "GET_PROJECT_DETAIL_SUCCESS";
export const CLEAR_PROJECT_DETAIL = "CLEAR_PROJECT_DETAIL";

//create project
export const CREATE_PROJECT_START = "CREATE_PROJECT_START";
export const CREATE_PROJECT_FAIL = "CREATE_PROJECT_FAIL";
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS";

//GET SITE VISIT DETAILS
export const GET_SITE_DETAILS_LOADING = "GET_SITE_DETAILS_LOADING";
export const GET_SITE_DETAILS_SUCCESS = "GET_SITE_DETAILS_SUCCESS";
export const GET_SITE_DETAILS_FAIL = "GET_SITE_DETAILS_FAIL";

//get vendor projects
export const GET_VENDOR_PROJECT_LIST_START = "GET_VENDOR_PROJECT_LIST_START";
export const GET_VENDOR_PROJECT_LIST_FAIL = "GET_VENDOR_PROJECT_LIST_FAIL";
export const GET_VENDOR_PROJECT_LIST_SUCCESS =
  "GET_VENDOR_PROJECT_LIST_SUCCESS";

//get vendor profile
export const GET_VENDOR_START = "GET_VENDOR_START";
export const GET_VENDOR_FAIL = "GET_VENDOR_FAIL";
export const GET_VENDOR_SUCCESS = "GET_VENDOR_SUCCESS";

// get vendor profile on customer side
export const GET_CUSTOMER_SIDE_VENDOR_PROFILE_START =
  "GET_CUSTOMER_SIDE_VENDOR_PROFILE_START";
export const GET_CUSTOMER_SIDE_VENDOR_PROFILE_FAIL =
  "GET_CUSTOMER_SIDE_VENDOR_PROFILE_FAIL";
export const GET_CUSTOMER_SIDE_VENDOR_PROFILE_SUCCESS =
  "GET_CUSTOMER_SIDE_VENDOR_PROFILE_SUCCESS";

//get vendor project details
export const GET_VENDOR_PROJECT_DETAIL_START =
  "GET_VENDOR_PROJECT_DETAIL_START";
export const GET_VENDOR_PROJECT_DETAIL_FAIL = "GET_VENDOR_PROJECT_DETAIL_FAIL";
export const GET_VENDOR_PROJECT_DETAIL_SUCCESS =
  "GET_VENDOR_PROJECT_DETAIL_SUCCESS";

//get HomePage details
export const GET_HOME_DETAIL_START = "GET_HOME_DETAIL_START";
export const GET_HOME_DETAIL_SUCCESS = "GET_HOME_DETAIL_SUCCESS";
export const GET_HOME_DETAIL_FAIL = "GET_HOME_DETAIL_FAIL";

//create auction API
export const CREATE_AUCTION_START = "CREATE_AUCTION_START";
export const CREATE_AUCTION_FAIL = "CREATE_AUCTION_FAIL";
export const CREATE_AUCTION_SUCCESS = "CREATE_AUCTION_SUCCESS";

//get specification API
export const GET_SPECIFICATION_START = "GET_SPECIFICATION_START";
export const GET_SPECIFICATION_FAIL = "GET_SPECIFICATION_FAIL";
export const GET_SPECIFICATION_SUCCESS = "GET_SPECIFICATION_SUCCESS";
//get auction API
export const GET_AUCTION_START = "GET_AUCTION_START";
export const GET_AUCTION_FAIL = "GET_AUCTION_FAIL";
export const GET_AUCTION_SUCCESS = "GET_AUCTION_SUCCESS";

//get specification details API
export const GET_SPECIFICATION_DETAIL_START = "GET_SPECIFICATION_DETAIL_START";
export const GET_SPECIFICATION_DETAIL_FAIL = "GET_SPECIFICATION_DETAIL_FAIL";
export const GET_SPECIFICATION_DETAIL_SUCCESS =
  "GET_SPECIFICATION_DETAIL_SUCCESS";

// create specification API
export const CREATE_SPECIFICATION_START = "CREATE_SPECIFICATION_DETAIL_START";
export const CREATE_SPECIFICATION_FAIL = "CREATE_SPECIFICATION_FAIL";
export const CREATE_SPECIFICATION_SUCCESS = "CREATE_SPECIFICATION_SUCCESS";

// update specification API
export const UPDATE_SPECIFICATION_START = "UPDATE_SPECIFICATION_START";
export const UPDATE_SPECIFICATION_FAIL = "UPDATE_SPECIFICATION_FAIL";
export const UPDATE_SPECIFICATION_SUCCESS = "UPDATE_SPECIFICATION_SUCCESS";

// delete specification API
export const DELETE_SPECIFICATION_START = "DELETE_SPECIFICATION_START";
export const DELETE_SPECIFICATION_FAIL = "DELETE_SPECIFICATION_FAIL";
export const DELETE_SPECIFICATION_SUCCESS = "DELETE_SPECIFICATION_SUCCESS";

//get search projects API
export const GET_SEARCH_START = "GET_SEARCH_START";
export const GET_SEARCH_FAIL = "GET_SEARCH_FAIL";
export const GET_SEARCH_SUCCESS = "GET_SEARCH_SUCCESS";

//get Scorecard API
export const GET_SCORECARD_START = "GET_SCORECARD_START";
export const GET_SCORECARD_FAIL = "GET_SCORECARD_FAIL";
export const GET_SCORECARD_SUCCESS = "GET_SCORECARD_SUCCESS";

//reset Password API
export const GET_RESET_START = "GET_RESET_START";
export const GET_RESET_SUCCESS = "GET_RESET_SUCCESS";
export const GET_RESET_FAIL = "GET_RESET_FAIL";

//change settings API
export const GET_CHANGE_START = "GET_CHANGE_START";
export const GET_CHANGE_SUCCESS = "GET_CHANGE_SUCCESS";
export const GET_CHANGE_FAIL = "GET_CHANGE_FAIL";

//project status consumer side
export const GET_VENDOR_DETAILS_LOADING = "GET_VENDOR_DETAILS_LOADING";
export const GET_VENDOR_DETAILS_SUCCESS = "GET_VENDOR_DETAILS_SUCCESS";
export const GET_VENDOR_DETAILS_FAIL = "GET_VENDOR_DETAILS_FAIL";

export const SHORTLIST_VENDORS = "SHORTLIST_VENDORS";
export const FINALIZE_MEETING = "FINALIZE_MEETING";

//  sub user

export const CREATE_SUB_USER = "CREATE_SUB_USER";
export const ADD_SUB_USER = "ADD_SUB_USER";
export const GET_SUB_USERS = " GET_SUB_USERS";
export const CLOSE_SNACK_BAR = "CLOSE_SNACK_BAR";
export const CREATE_SUB_USER_FAIL = "CREATE_SUB_USER_FAIL";

// secondary email
export const GET_SECONDARY_EMAILS_START = "GET_SECONDARY_EMAILS_START";
export const GET_SECONDARY_EMAILS_SUCCESS = "GET_SECONDARY_EMAILS_SUCCESS";
export const GET_SECONDARY_EMAILS_FAIL = "GET_SECONDARY_EMAILS_FAIL";

// Bid Fail

export const BID_FAIL = "BID_FAIL";
export const BID_SUCCESS = "BID_SUCCESS";
export const CLOSE_SNACKBAR_BID = "CLOSE_SNACKBAR_BID";

// START HOSTED BID
export const START_HOSTED_BID_LOADING = "START_HOSTED_BID_LOADING";
export const START_HOSTED_BID_SUCCESS = "START_HOSTED_BID_SUCCESS";
export const START_HOSTED_BID_FAILURE = "START_HOSTED_BID_FAILURE";
export const START_HOSTED_BID_RESET = "START_HOSTED_BID_RESET";

// Request Demo

export const REQUEST_DEMO_START = "REQUEST_DEMO_START";
export const REQUEST_DEMO_SUCCESS = "REQUEST_DEMO_SUCCESS";
export const REQUEST_DEMO_FAILURE = "REQUEST_DEMO_FAILURE";

// Get Pending Vendor Profile
export const GET_PENDING_VENDOR_START = "GET_PENDING_VENDOR_START";
export const GET_PENDING_VENDOR_SUCCESS = "GET_PENDING_VENDOR_SUCCESS";
export const GET_PENDING_VENDOR_FAILURE = "GET_PENDING_VENDOR_FAILURE";

// GET OTP
export const GET_OTP_LOADING = "GET_OTP_LOADING";
export const GET_OTP_SUCCESS = "GET_OTP_SUCCESS";
export const GET_OTP_FAILURE = "GET_OTP_FAILURE";
export const GET_OTP_RESET = "GET_OTP_RESET";

//POST GET SUPPORT
export const POST_GET_SUPPORT_LOADING = "POST_GET_SUPPORT_LOADING";
export const POST_GET_SUPPORT_SUCCESS = "POST_GET_SUPPORT_SUCCESS";
export const POST_GET_SUPPORT_FAILURE = "POST_GET_SUPPORT_FAILURE";
export const POST_GET_SUPPORT_RESET = "POST_GET_SUPPORT_RESET";

// GET JOBS
export const GET_JOBS_LOADING = "GET_JOBS_LOADING";
export const GET_JOBS_SUCCESS = "GET_JOBS_SUCCESS";
export const GET_JOBS_FAILURE = "GET_JOBS_FAILURE";
export const GET_JOBS_RESET = "GET_JOBS_RESET";

// APPLY FOR JOB
export const APPLY_FOR_JOB_LOADING = "APPLY_FOR_JOB_LOADING";
export const APPLY_FOR_JOB_SUCCESS = "APPLY_FOR_JOB_SUCCESS";
export const APPLY_FOR_JOB_FAILURE = "APPLY_FOR_JOB_FAILURE";

//POST NEWSLETTER SIGNUP
export const POST_NEWSLETTER_SIGNUP_LOADING = "POST_NEWSLETTER_SIGNUP_LOADING";
export const POST_NEWSLETTER_SIGNUP_SUCCESS = "POST_NEWSLETTER_SIGNUP_SUCCESS";
export const POST_NEWSLETTER_SIGNUP_FAILURE = "POST_NEWSLETTER_SIGNUP_FAILURE";
export const POST_NEWSLETTER_SIGNUP_RESET = "POST_NEWSLETTER_SIGNUP_RESET";

// ADD CUSTOMER SECONDARY EMAILS
export const ADD_CUSTOMER_SECONDARY_EMAILS_LOADING =
  "ADD_CUSTOMER_SECONDARY_EMAILS_LOADING";
export const ADD_CUSTOMER_SECONDARY_EMAILS_SUCCESS =
  "ADD_CUSTOMER_SECONDARY_EMAILS_SUCCESS";
export const ADD_CUSTOMER_SECONDARY_EMAILS_FAILURE =
  "ADD_CUSTOMER_SECONDARY_EMAILS_FAILURE";
export const ADD_CUSTOMER_SECONDARY_EMAILS_RESET =
  "ADD_CUSTOMER_SECONDARY_EMAILS_RESET";

// GET PARTICIPANTS
export const GET_PARTICIPANTS_LOADING = "GET_PARTICIPANTS_LOADING";
export const GET_PARTICIPANTS_SUCCESS = "GET_PARTICIPANTS_SUCCESS";
export const GET_PARTICIPANTS_FAIL = "GET_PARTICIPANTS_FAIL";

// ADD PROJECT DOCUMENTS
export const ADD_PROJECT_DOCUMENTS_LOADING = "ADD_PROJECT_DOCUMENTS_LOADING";
export const ADD_PROJECT_DOCUMENTS_SUCCESS = "ADD_PROJECT_DOCUMENTS_SUCCESS";
export const ADD_PROJECT_DOCUMENTS_FAILURE = "ADD_PROJECT_DOCUMENTS_FAILURE";
export const ADD_PROJECT_DOCUMENTS_RESET = "ADD_PROJECT_DOCUMENTS_RESET";

// DELETE PROJECT DOCUMENTS
export const DELETE_PROJECT_DOCUMENTS_LOADING =
  "DELETE_PROJECT_DOCUMENTS_LOADING";
export const DELETE_PROJECT_DOCUMENTS_SUCCESS =
  "DELETE_PROJECT_DOCUMENTS_SUCCESS";
export const DELETE_PROJECT_DOCUMENTS_FAILURE =
  "DELETE_PROJECT_DOCUMENTS_FAILURE";
export const DELETE_PROJECT_DOCUMENTS_RESET = "DELETE_PROJECT_DOCUMENTS_RESET";

// GET ALL VENDORS
export const GET_ALL_VENDORS_LOADING = "GET_ALL_VENDORS_LOADING";
export const GET_ALL_VENDORS_SUCCESS = "GET_ALL_VENDORS_SUCCESS";
export const GET_ALL_VENDORS_FAIL = "GET_ALL_VENDORS_FAIL";

// CREATE NEW VENDOR
export const CREATE_NEW_VENDOR_LOADING = "CREATE_NEW_VENDOR_LOADING";
export const CREATE_NEW_VENDOR_SUCCESS = "CREATE_NEW_VENDOR_SUCCESS";
export const CREATE_NEW_VENDOR_FAILURE = "CREATE_NEW_VENDOR_FAILURE";
export const CREATE_NEW_VENDOR_RESET = "CREATE_NEW_VENDOR_RESET";

// HOST AUCTION
export const HOST_AUCTION_LOADING = "HOST_AUCTION_LOADING";
export const HOST_AUCTION_SUCCESS = "HOST_AUCTION_SUCCESS";
export const HOST_AUCTION_FAILURE = "HOST_AUCTION_FAILURE";
export const HOST_AUCTION_RESET = "HOST_AUCTION_RESET";

// CREATE HOST AUCTION PROJECT
export const CREATE_HOST_AUCTION_PROJECT_LOADING =
  "CREATE_HOST_AUCTION_PROJECT_LOADING";
export const CREATE_HOST_AUCTION_PROJECT_SUCCESS =
  "CREATE_HOST_AUCTION_PROJECT_SUCCESS";
export const CREATE_HOST_AUCTION_PROJECT_FAILURE =
  "CREATE_HOST_AUCTION_PROJECT_FAILURE";
export const CREATE_HOST_AUCTION_PROJECT_RESET =
  "CREATE_HOST_AUCTION_PROJECT_RESET";

// ADD HOST AUCTION DOCUMENTS
export const ADD_HOST_AUCTION_DOCS_LOADING = "ADD_HOST_AUCTION_DOCS_LOADING";
export const ADD_HOST_AUCTION_DOCS_SUCCESS = "ADD_HOST_AUCTION_DOCS_SUCCESS";
export const ADD_HOST_AUCTION_DOCS_FAILURE = "ADD_HOST_AUCTION_DOCS_FAILURE";
export const ADD_HOST_AUCTION_DOCS_RESET = "ADD_HOST_AUCTION_DOCS_RESET";

// ADD PROJECT VENDORS
export const ADD_PROJECT_VENDORS_LOADING = "ADD_PROJECT_VENDORS_LOADING";
export const ADD_PROJECT_VENDORS_SUCCESS = "ADD_PROJECT_VENDORS_SUCCESS";
export const ADD_PROJECT_VENDORS_FAILURE = "ADD_PROJECT_VENDORS_FAILURE";
export const ADD_PROJECT_VENDORS_RESET = "ADD_PROJECT_VENDORS_RESET";

// UPDATE VENDOR PROJECT
export const UPDATE_VENDOR_PROJECT_LOADING = "UPDATE_VENDOR_PROJECT_LOADING";
export const UPDATE_VENDOR_PROJECT_SUCCESS = "UPDATE_VENDOR_PROJECT_SUCCESS";
export const UPDATE_VENDOR_PROJECT_FAILURE = "UPDATE_VENDOR_PROJECT_FAILURE";

// UPDATE VENDOR AWARD
export const UPDATE_VENDOR_AWARD_LOADING = "UPDATE_VENDOR_AWARD_LOADING";
export const UPDATE_VENDOR_AWARD_SUCCESS = "UPDATE_VENDOR_AWARD_SUCCESS";
export const UPDATE_VENDOR_AWARD_FAILURE = "UPDATE_VENDOR_AWARD_FAILURE";

// UPDATE VENDOR KEY PERSONNEL
export const UPDATE_VENDOR_KEY_PERSONNEL_LOADING =
  "UPDATE_VENDOR_KEY_PERSONNEL_LOADING";
export const UPDATE_VENDOR_KEY_PERSONNEL_SUCCESS =
  "UPDATE_VENDOR_KEY_PERSONNEL_SUCCESS";
export const UPDATE_VENDOR_KEY_PERSONNEL_FAILURE =
  "UPDATE_VENDOR_KEY_PERSONNEL_FAILURE";

// UPDATE VENDOR TESTIMONIAL
export const UPDATE_VENDOR_TESTIMONIAL_LOADING =
  "UPDATE_VENDOR_TESTIMONIAL_LOADING";
export const UPDATE_VENDOR_TESTIMONIAL_SUCCESS =
  "UPDATE_VENDOR_TESTIMONIAL_SUCCESS";
export const UPDATE_VENDOR_TESTIMONIAL_FAILURE =
  "UPDATE_VENDOR_TESTIMONIAL_FAILURE";

// GET VENDOR PROFILE DETAILS ON VENDOR ADMIN SIDE
export const GET_VENDOR_ADMIN_SIDE_VENDOR_PROFILE_DETAILS_START =
  "GET_VENDOR_ADMIN_SIDE_VENDOR_PROFILE_DETAILS_START";
export const GET_VENDOR_ADMIN_SIDE_VENDOR_PROFILE_DETAILS_FAIL =
  "GET_VENDOR_ADMIN_SIDE_VENDOR_PROFILE_DETAILS_FAIL";
export const GET_VENDOR_ADMIN_SIDE_VENDOR_PROFILE_DETAILS_SUCCESS =
  "GET_VENDOR_ADMIN_SIDE_VENDOR_PROFILE_DETAILS_SUCCESS";

// UPDATE VENDOR PROFILE
export const UPDATE_VENDOR_PROFILE_LOADING = "UPDATE_VENDOR_PROFILE_LOADING";
export const UPDATE_VENDOR_PROFILE_SUCCESS = "UPDATE_VENDOR_PROFILE_SUCCESS";
export const UPDATE_VENDOR_PROFILE_FAILURE = "UPDATE_VENDOR_PROFILE_FAILURE";

// ADD VENDOR PROJECT
export const ADD_VENDOR_PROJECT_LOADING = "ADD_VENDOR_PROJECT_LOADING";
export const ADD_VENDOR_PROJECT_SUCCESS = "ADD_VENDOR_PROJECT_SUCCESS";
export const ADD_VENDOR_PROJECT_FAILURE = "ADD_VENDOR_PROJECT_FAILURE";

// DELETE VENDOR PROJECT
export const DELETE_VENDOR_PROJECT_LOADING = "DELETE_VENDOR_PROJECT_LOADING";
export const DELETE_VENDOR_PROJECT_SUCCESS = "DELETE_VENDOR_PROJECT_SUCCESS";
export const DELETE_VENDOR_PROJECT_FAILURE = "DELETE_VENDOR_PROJECT_FAILURE";

// ADD VENDOR AWARD
export const ADD_VENDOR_AWARD_LOADING = "ADD_VENDOR_AWARD_LOADING";
export const ADD_VENDOR_AWARD_SUCCESS = "ADD_VENDOR_AWARD_SUCCESS";
export const ADD_VENDOR_AWARD_FAILURE = "ADD_VENDOR_AWARD_FAILURE";

// DELETE VENDOR AWARD
export const DELETE_VENDOR_AWARD_LOADING = "DELETE_VENDOR_AWARD_LOADING";
export const DELETE_VENDOR_AWARD_SUCCESS = "DELETE_VENDOR_AWARD_SUCCESS";
export const DELETE_VENDOR_AWARD_FAILURE = "DELETE_VENDOR_AWARD_FAILURE";

// ADD VENDOR KEY PERSONNEL
export const ADD_VENDOR_KEY_PERSONNEL_LOADING =
  "ADD_VENDOR_KEY_PERSONNEL_LOADING";
export const ADD_VENDOR_KEY_PERSONNEL_SUCCESS =
  "ADD_VENDOR_KEY_PERSONNEL_SUCCESS";
export const ADD_VENDOR_KEY_PERSONNEL_FAILURE =
  "ADD_VENDOR_KEY_PERSONNEL_FAILURE";

// DELETE VENDOR KEY PERSONNEL
export const DELETE_VENDOR_KEY_PERSONNEL_LOADING =
  "DELETE_VENDOR_KEY_PERSONNEL_LOADING";
export const DELETE_VENDOR_KEY_PERSONNEL_SUCCESS =
  "DELETE_VENDOR_KEY_PERSONNEL_SUCCESS";
export const DELETE_VENDOR_KEY_PERSONNEL_FAILURE =
  "DELETE_VENDOR_KEY_PERSONNEL_FAILURE";

// ADD VENDOR TESTIMONIAL
export const ADD_VENDOR_TESTIMONIAL_LOADING = "ADD_VENDOR_TESTIMONIAL_LOADING";
export const ADD_VENDOR_TESTIMONIAL_SUCCESS = "ADD_VENDOR_TESTIMONIAL_SUCCESS";
export const ADD_VENDOR_TESTIMONIAL_FAILURE = "ADD_VENDOR_TESTIMONIAL_FAILURE";

// DELETE VENDOR TESTIMONIAL
export const DELETE_VENDOR_TESTIMONIAL_LOADING =
  "DELETE_VENDOR_TESTIMONIAL_LOADING";
export const DELETE_VENDOR_TESTIMONIAL_SUCCESS =
  "DELETE_VENDOR_TESTIMONIAL_SUCCESS";
export const DELETE_VENDOR_TESTIMONIAL_FAILURE =
  "DELETE_VENDOR_TESTIMONIAL_FAILURE";

// UPDATE PENDING VENDOR PROFILE
export const UPDATE_PENDING_VENDOR_PROFILE_LOADING =
  "UPDATE_PENDING_VENDOR_PROFILE_LOADING";
export const UPDATE_PENDING_VENDOR_PROFILE_SUCCESS =
  "UPDATE_PENDING_VENDOR_PROFILE_SUCCESS";
export const UPDATE_PENDING_VENDOR_PROFILE_FAILURE =
  "UPDATE_PENDING_VENDOR_PROFILE_FAILURE";

// UPDATE PENDING VENDOR PROJECT
export const UPDATE_PENDING_VENDOR_PROJECT_LOADING =
  "UPDATE_PENDING_VENDOR_PROJECT_LOADING";
export const UPDATE_PENDING_VENDOR_PROJECT_SUCCESS =
  "UPDATE_PENDING_VENDOR_PROJECT_SUCCESS";
export const UPDATE_PENDING_VENDOR_PROJECT_FAILURE =
  "UPDATE_PENDING_VENDOR_PROJECT_FAILURE";

// DELETE VENDOR PROJECT
export const DELETE_PENDING_VENDOR_PROJECT_LOADING =
  "DELETE_PENDING_VENDOR_PROJECT_LOADING";
export const DELETE_PENDING_VENDOR_PROJECT_SUCCESS =
  "DELETE_PENDING_VENDOR_PROJECT_SUCCESS";
export const DELETE_PENDING_VENDOR_PROJECT_FAILURE =
  "DELETE_PENDING_VENDOR_PROJECT_FAILURE";

// UPDATE PENDING VENDOR AWARD
export const UPDATE_PENDING_VENDOR_AWARD_LOADING =
  "UPDATE_PENDING_VENDOR_AWARD_LOADING";
export const UPDATE_PENDING_VENDOR_AWARD_SUCCESS =
  "UPDATE_PENDING_VENDOR_AWARD_SUCCESS";
export const UPDATE_PENDING_VENDOR_AWARD_FAILURE =
  "UPDATE_PENDING_VENDOR_AWARD_FAILURE";

// DELETE PENDING VENDOR AWARD
export const DELETE_PENDING_VENDOR_AWARD_LOADING =
  "DELETE_PENDING_VENDOR_AWARD_LOADING";
export const DELETE_PENDING_VENDOR_AWARD_SUCCESS =
  "DELETE_PENDING_VENDOR_AWARD_SUCCESS";
export const DELETE_PENDING_VENDOR_AWARD_FAILURE =
  "DELETE_PENDING_VENDOR_AWARD_FAILURE";

// UPDATE PENDING VENDOR KEY PERSONNEL
export const UPDATE_PENDING_VENDOR_KEY_PERSONNEL_LOADING =
  "UPDATE_PENDING_VENDOR_KEY_PERSONNEL_LOADING";
export const UPDATE_PENDING_VENDOR_KEY_PERSONNEL_SUCCESS =
  "UPDATE_PENDING_VENDOR_KEY_PERSONNEL_SUCCESS";
export const UPDATE_PENDING_VENDOR_KEY_PERSONNEL_FAILURE =
  "UPDATE_PENDING_VENDOR_KEY_PERSONNEL_FAILURE";

// DELETE PENDING VENDOR KEY PERSONNEL
export const DELETE_PENDING_VENDOR_KEY_PERSONNEL_LOADING =
  "DELETE_PENDING_VENDOR_KEY_PERSONNEL_LOADING";
export const DELETE_PENDING_VENDOR_KEY_PERSONNEL_SUCCESS =
  "DELETE_PENDING_VENDOR_KEY_PERSONNEL_SUCCESS";
export const DELETE_PENDING_VENDOR_KEY_PERSONNEL_FAILURE =
  "DELETE_PENDING_VENDOR_KEY_PERSONNEL_FAILURE";

// UPDATE PENDING VENDOR TESTIMONIAL
export const UPDATE_PENDING_VENDOR_TESTIMONIAL_LOADING =
  "UPDATE_PENDING_VENDOR_TESTIMONIAL_LOADING";
export const UPDATE_PENDING_VENDOR_TESTIMONIAL_SUCCESS =
  "UPDATE_PENDING_VENDOR_TESTIMONIAL_SUCCESS";
export const UPDATE_PENDING_VENDOR_TESTIMONIAL_FAILURE =
  "UPDATE_PENDING_VENDOR_TESTIMONIAL_FAILURE";

// DELETE PENDING VENDOR TESTIMONIAL
export const DELETE_PENDING_VENDOR_TESTIMONIAL_LOADING =
  "DELETE_PENDING_VENDOR_TESTIMONIAL_LOADING";
export const DELETE_PENDING_VENDOR_TESTIMONIAL_SUCCESS =
  "DELETE_PENDING_VENDOR_TESTIMONIAL_SUCCESS";
export const DELETE_PENDING_VENDOR_TESTIMONIAL_FAILURE =
  "DELETE_PENDING_VENDOR_TESTIMONIAL_FAILURE";

// GET VENDOR ADMIN TOKEN
export const GET_VENDOR_ADMIN_TOKEN_LOADING = "GET_VENDOR_ADMIN_TOKEN_LOADING";
export const GET_VENDOR_ADMIN_TOKEN_SUCCESS = "GET_VENDOR_ADMIN_TOKEN_SUCCESS";
export const GET_VENDOR_ADMIN_TOKEN_FAILURE = "GET_VENDOR_ADMIN_TOKEN_FAILURE";

// GET NON AUCTION QUOTES
export const GET_NON_AUCTION_QUOTES_LOADING = "GET_NON_AUCTION_QUOTES_LOADING";
export const GET_NON_AUCTION_QUOTES_SUCCESS = "GET_NON_AUCTION_QUOTES_SUCCESS";
export const GET_NON_AUCTION_QUOTES_FAILURE = "GET_NON_AUCTION_QUOTES_FAILURE";
export const GET_NON_AUCTION_QUOTES_RESET = "GET_NON_AUCTION_QUOTES_RESET";

// ADD NON AUCTION QUOTE
export const ADD_NON_AUCTION_QUOTE_LOADING = "ADD_NON_AUCTION_QUOTE_LOADING";
export const ADD_NON_AUCTION_QUOTE_SUCCESS = "ADD_NON_AUCTION_QUOTE_SUCCESS";
export const ADD_NON_AUCTION_QUOTE_FAILURE = "ADD_NON_AUCTION_QUOTE_FAILURE";
export const ADD_NON_AUCTION_QUOTE_RESET = "ADD_NON_AUCTION_QUOTE_RESET";

// GET DATA DASHBOARD
export const GET_DATA_DASHBOARD_LOADING = "GET_DATA_DASHBOARD_LOADING";
export const GET_DATA_DASHBOARD_SUCCESS = "GET_DATA_DASHBOARD_SUCCESS";
export const GET_DATA_DASHBOARD_FAILURE = "GET_DATA_DASHBOARD_FAILURE";
export const GET_DATA_DASHBOARD_RESET = "GET_DATA_DASHBOARD_RESET";

// GET DATA DASHBOARD VENDOR PROFILE
export const GET_DATA_DASHBOARD_VENDOR_PROFILE_LOADING =
  "GET_DATA_DASHBOARD_VENDOR_PROFILE_LOADING";
export const GET_DATA_DASHBOARD_VENDOR_PROFILE_SUCCESS =
  "GET_DATA_DASHBOARD_VENDOR_PROFILE_SUCCESS";
export const GET_DATA_DASHBOARD_VENDOR_PROFILE_FAILURE =
  "GET_DATA_DASHBOARD_VENDOR_PROFILE_FAILURE";
export const GET_DATA_DASHBOARD_VENDOR_PROFILE_RESET =
  "GET_DATA_DASHBOARD_VENDOR_PROFILE_RESET";

// GET DATA DASHBOARD COMPANIES
export const GET_DATA_DASHBOARD_COMPANIES_LOADING =
  "GET_DATA_DASHBOARD_COMPANIES_LOADING";
export const GET_DATA_DASHBOARD_COMPANIES_SUCCESS =
  "GET_DATA_DASHBOARD_COMPANIES_SUCCESS";
export const GET_DATA_DASHBOARD_COMPANIES_FAILURE =
  "GET_DATA_DASHBOARD_COMPANIES_FAILURE";
export const GET_DATA_DASHBOARD_COMPANIES_RESET =
  "GET_DATA_DASHBOARD_COMPANIES_RESET";

// GET DATA DASHBOARD NEW SIGNUPS
export const GET_DATA_DASHBOARD_NEW_SIGNUPS_LOADING =
  "GET_DATA_DASHBOARD_NEW_SIGNUPS_LOADING";
export const GET_DATA_DASHBOARD_NEW_SIGNUPS_SUCCESS =
  "GET_DATA_DASHBOARD_NEW_SIGNUPS_SUCCESS";
export const GET_DATA_DASHBOARD_NEW_SIGNUPS_FAILURE =
  "GET_DATA_DASHBOARD_NEW_SIGNUPS_FAILURE";
export const GET_DATA_DASHBOARD_NEW_SIGNUPS_RESET =
  "GET_DATA_DASHBOARD_NEW_SIGNUPS_RESET";

// DATA DASHBOARD LOGIN
export const DATA_DASHBOARD_LOGIN_LOADING = "DATA_DASHBOARD_LOGIN_LOADING";
export const DATA_DASHBOARD_LOGIN_SUCCESS = "DATA_DASHBOARD_LOGIN_SUCCESS";
export const DATA_DASHBOARD_LOGIN_FAILURE = "DATA_DASHBOARD_LOGIN_FAILURE";
export const DATA_DASHBOARD_LOGIN_RESET = "DATA_DASHBOARD_LOGIN_RESET";

// SOLARFLOW SIGNUP
export const SOLARFLOW_SIGNUP_START = "SOLARFLOW_SIGNUP_START";
export const SOLARFLOW_SIGNUP_SUCCESS = "SOLARFLOW_SIGNUP_SUCCESS";
export const SOLARFLOW_SIGNUP_FAILURE = "SOLARFLOW_SIGNUP_FAILURE";

// LOGIN TO SOLARFLOW
export const LOGIN_TO_SOLARFLOW_LOADING = "LOGIN_TO_SOLARFLOW_LOADING";
export const LOGIN_TO_SOLARFLOW_SUCCESS = "LOGIN_TO_SOLARFLOW_SUCCESS";
export const LOGIN_TO_SOLARFLOW_FAILURE = "LOGIN_TO_SOLARFLOW_FAILURE";

// VENDOR REQUEST FINANCE
export const VENDOR_REQUEST_FINANCE_LOADING = "VENDOR_REQUEST_FINANCE_LOADING";
export const VENDOR_REQUEST_FINANCE_SUCCESS = "VENDOR_REQUEST_FINANCE_SUCCESS";
export const VENDOR_REQUEST_FINANCE_FAILURE = "VENDOR_REQUEST_FINANCE_FAILURE";
export const VENDOR_REQUEST_FINANCE_RESET = "VENDOR_REQUEST_FINANCE_RESET";

// CUSTOMER REQUEST FINANCE
export const CUSTOMER_REQUEST_FINANCE_LOADING = "CUSTOMER_REQUEST_FINANCE_LOADING";
export const CUSTOMER_REQUEST_FINANCE_SUCCESS = "CUSTOMER_REQUEST_FINANCE_SUCCESS";
export const CUSTOMER_REQUEST_FINANCE_FAILURE = "CUSTOMER_REQUEST_FINANCE_FAILURE";
export const CUSTOMER_REQUEST_FINANCE_RESET = "CUSTOMER_REQUEST_FINANCE_RESET";

// GET SAFEARTH DASHBOARD
export const GET_SAFEARTH_DASHBOARD_LOADING = "GET_SAFEARTH_DASHBOARD_LOADING";
export const GET_SAFEARTH_DASHBOARD_SUCCESS = "GET_SAFEARTH_DASHBOARD_SUCCESS";
export const GET_SAFEARTH_DASHBOARD_FAILURE = "GET_SAFEARTH_DASHBOARD_FAILURE";
export const GET_SAFEARTH_DASHBOARD_RESET = "GET_SAFEARTH_DASHBOARD_RESET";

// GET ACTIVE CONSUMER PROJECTS FROM SOLARFLOW
export const GET_ACTIVE_CONSUMER_PROJECTS_FROM_SOLARFLOW_LOADING = "GET_ACTIVE_CONSUMER_PROJECTS_FROM_SOLARFLOW_LOADING";
export const GET_ACTIVE_CONSUMER_PROJECTS_FROM_SOLARFLOW_SUCCESS = "GET_ACTIVE_CONSUMER_PROJECTS_FROM_SOLARFLOW_SUCCESS";
export const GET_ACTIVE_CONSUMER_PROJECTS_FROM_SOLARFLOW_FAILURE = "GET_ACTIVE_CONSUMER_PROJECTS_FROM_SOLARFLOW_FAILURE";
export const GET_ACTIVE_CONSUMER_PROJECTS_FROM_SOLARFLOW_RESET = "GET_ACTIVE_CONSUMER_PROJECTS_FROM_SOLARFLOW_RESET";

// GET SAFEARTH DASHBOARD FINANCE DETAILS
export const GET_SAFEARTH_DASHBOARD_FINANCE_DETAILS_LOADING =
  "GET_SAFEARTH_DASHBOARD_FINANCE_DETAILS_LOADING";
export const GET_SAFEARTH_DASHBOARD_FINANCE_DETAILS_SUCCESS =
  "GET_SAFEARTH_DASHBOARD_FINANCE_DETAILS_SUCCESS";
export const GET_SAFEARTH_DASHBOARD_FINANCE_DETAILS_FAILURE =
  "GET_SAFEARTH_DASHBOARD_FINANCE_DETAILS_FAILURE";

// SAVE NEW VENDOR CONTACT INFO
export const SAVE_NEW_VENDOR_CONTACT_INFO_LOADING = "SAVE_NEW_VENDOR_CONTACT_INFO_LOADING";
export const SAVE_NEW_VENDOR_CONTACT_INFO_SUCCESS = "SAVE_NEW_VENDOR_CONTACT_INFO_SUCCESS";
export const SAVE_NEW_VENDOR_CONTACT_INFO_FAILURE = "SAVE_NEW_VENDOR_CONTACT_INFO_FAILURE";
export const SAVE_NEW_VENDOR_CONTACT_INFO_RESET = "SAVE_NEW_VENDOR_CONTACT_INFO_RESET";

// ADD BULK PENDING PROJECTS
export const ADD_BULK_PENDING_PROJECTS_LOADING = "ADD_BULK_PENDING_PROJECTS_LOADING";
export const ADD_BULK_PENDING_PROJECTS_SUCCESS = "ADD_BULK_PENDING_PROJECTS_SUCCESS";
export const ADD_BULK_PENDING_PROJECTS_FAILURE = "ADD_BULK_PENDING_PROJECTS_FAILURE";
export const ADD_BULK_PENDING_PROJECTS_RESET = "ADD_BULK_PENDING_PROJECTS_RESET";