import {
    GET_PARTICIPANTS_LOADING,
    GET_PARTICIPANTS_SUCCESS,
    GET_PARTICIPANTS_FAIL,
} from "../../actions/actionTypes";

const INITIAL_STATE = {
    participants: {
        get: {
            loading: false,
            reset: false,
            success: {
                ok: false,
                data: [],
            },
            failure: {
                error: false,
                message: "",
            },
        },
    },
};

const participantsReducers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_PARTICIPANTS_LOADING:
            return {
                ...state,
                participants: {
                    ...state.participants,
                    get: {
                        ...state.participants.get,
                        loading: true,
                        reset: false,
                        success: {
                            ...state.participants.get.success,
                            ok: false,
                        },
                        failure: {
                            error: false,
                            message: "",
                        },
                    },
                },
            };
        case GET_PARTICIPANTS_SUCCESS:
            return {
                ...state,
                participants: {
                    ...state.participants,
                    get: {
                        ...state.participants.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.participants.get.success,
                            ok: true,
                            data: action.payload,
                        },
                        failure: {
                            error: false,
                            message: "",
                        },
                    },
                },
            };
        case GET_PARTICIPANTS_FAIL:
            return {
                ...state,
                participants: {
                    ...state.participants,
                    get: {
                        ...state.participants.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.participants.get.success,
                            ok: false,
                            data: [],
                        },
                        failure: {
                            error: true,
                            message: action.payload.message,
                        },
                    },
                },
            };
    }
    return state;
};

export default participantsReducers;
