import {
  GET_DATA_DASHBOARD_FAILURE,
  GET_DATA_DASHBOARD_LOADING,
  GET_DATA_DASHBOARD_RESET,
  GET_DATA_DASHBOARD_SUCCESS,
  GET_DATA_DASHBOARD_VENDOR_PROFILE_LOADING,
  GET_DATA_DASHBOARD_VENDOR_PROFILE_SUCCESS,
  GET_DATA_DASHBOARD_VENDOR_PROFILE_FAILURE,
  GET_DATA_DASHBOARD_VENDOR_PROFILE_RESET,
  GET_DATA_DASHBOARD_COMPANIES_LOADING,
  GET_DATA_DASHBOARD_COMPANIES_SUCCESS,
  GET_DATA_DASHBOARD_COMPANIES_FAILURE,
  GET_DATA_DASHBOARD_COMPANIES_RESET,
  GET_DATA_DASHBOARD_NEW_SIGNUPS_LOADING,
  GET_DATA_DASHBOARD_NEW_SIGNUPS_SUCCESS,
  GET_DATA_DASHBOARD_NEW_SIGNUPS_FAILURE,
  GET_DATA_DASHBOARD_NEW_SIGNUPS_RESET,
  DATA_DASHBOARD_LOGIN_LOADING,
  DATA_DASHBOARD_LOGIN_SUCCESS,
  DATA_DASHBOARD_LOGIN_FAILURE,
  DATA_DASHBOARD_LOGIN_RESET,
} from "../../actions/actionTypes";

const INITIAL_STATE = {
  dataDashboard: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
  vendorProfile: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
  companies: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
  newSignups: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
  login: {
    post: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
};

const dataDashboardReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_DATA_DASHBOARD_LOADING:
      return {
        ...state,
        dataDashboard: {
          ...state.dataDashboard,
          get: {
            ...state.dataDashboard.get,
            loading: true,
            reset: false,
            success: {
              ...state.dataDashboard.get.success,
              ok: false,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case GET_DATA_DASHBOARD_SUCCESS:
      return {
        ...state,
        dataDashboard: {
          ...state.dataDashboard,
          get: {
            ...state.dataDashboard.get,
            loading: false,
            reset: false,
            success: {
              ...state.dataDashboard.get.success,
              ok: true,
              data: action.payload.data,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case GET_DATA_DASHBOARD_FAILURE:
      return {
        ...state,
        dataDashboard: {
          ...state.dataDashboard,
          get: {
            ...state.dataDashboard.get,
            loading: false,
            reset: false,
            success: {
              ...state.dataDashboard.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
              accessDenied: action.payload.accessDenied,
            },
          },
        },
      };
    case GET_DATA_DASHBOARD_RESET:
      return {
        ...state,
        dataDashboard: {
          ...state.dataDashboard.get,
          get: {
            ...INITIAL_STATE.dataDashboard.post,
            reset: true,
          },
        },
      };
    case GET_DATA_DASHBOARD_VENDOR_PROFILE_LOADING:
      return {
        ...state,
        vendorProfile: {
          ...state.vendorProfile,
          get: {
            ...state.vendorProfile.get,
            loading: true,
            reset: false,
            success: {
              ...state.vendorProfile.get.success,
              ok: false,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case GET_DATA_DASHBOARD_VENDOR_PROFILE_SUCCESS:
      return {
        ...state,
        vendorProfile: {
          ...state.vendorProfile,
          get: {
            ...state.vendorProfile.get,
            loading: false,
            reset: false,
            success: {
              ...state.vendorProfile.get.success,
              ok: true,
              data: action.payload.vendor,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case GET_DATA_DASHBOARD_VENDOR_PROFILE_FAILURE:
      return {
        ...state,
        vendorProfile: {
          ...state.vendorProfile,
          get: {
            ...state.vendorProfile.get,
            loading: false,
            reset: false,
            success: {
              ...state.vendorProfile.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };
    case GET_DATA_DASHBOARD_VENDOR_PROFILE_RESET:
      return {
        ...state,
        vendorProfile: {
          ...state.vendorProfile.get,
          get: {
            ...INITIAL_STATE.vendorProfile.get,
            reset: true,
          },
        },
      };
    case GET_DATA_DASHBOARD_COMPANIES_LOADING:
      return {
        ...state,
        companies: {
          ...state.companies,
          get: {
            ...state.companies.get,
            loading: true,
            reset: false,
            success: {
              ...state.companies.get.success,
              ok: false,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case GET_DATA_DASHBOARD_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: {
          ...state.companies,
          get: {
            ...state.companies.get,
            loading: false,
            reset: false,
            success: {
              ...state.companies.get.success,
              ok: true,
              data: action.payload.data,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case GET_DATA_DASHBOARD_COMPANIES_FAILURE:
      return {
        ...state,
        companies: {
          ...state.companies,
          get: {
            ...state.companies.get,
            loading: false,
            reset: false,
            success: {
              ...state.companies.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };
    case GET_DATA_DASHBOARD_COMPANIES_RESET:
      return {
        ...state,
        companies: {
          ...state.companies.get,
          get: {
            ...INITIAL_STATE.companies.post,
            reset: true,
          },
        },
      };
    case GET_DATA_DASHBOARD_NEW_SIGNUPS_LOADING:
      return {
        ...state,
        newSignups: {
          ...state.newSignups,
          get: {
            ...state.newSignups.get,
            loading: true,
            reset: false,
            success: {
              ...state.newSignups.get.success,
              ok: false,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case GET_DATA_DASHBOARD_NEW_SIGNUPS_SUCCESS:
      return {
        ...state,
        newSignups: {
          ...state.newSignups,
          get: {
            ...state.newSignups.get,
            loading: false,
            reset: false,
            success: {
              ...state.newSignups.get.success,
              ok: true,
              data: action.payload.data,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case GET_DATA_DASHBOARD_NEW_SIGNUPS_FAILURE:
      return {
        ...state,
        newSignups: {
          ...state.newSignups,
          get: {
            ...state.newSignups.get,
            loading: false,
            reset: false,
            success: {
              ...state.newSignups.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };
    case GET_DATA_DASHBOARD_NEW_SIGNUPS_RESET:
      return {
        ...state,
        newSignups: {
          ...state.newSignups.get,
          get: {
            ...INITIAL_STATE.newSignups.post,
            reset: true,
          },
        },
      };
    case DATA_DASHBOARD_LOGIN_LOADING:
      return {
        ...state,
        login: {
          ...state.login,
          post: {
            ...state.login.post,
            loading: true,
            reset: false,
            success: {
              ...state.login.post.success,
              ok: false,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case DATA_DASHBOARD_LOGIN_SUCCESS:
      return {
        ...state,
        login: {
          ...state.login,
          post: {
            ...state.login.post,
            loading: false,
            reset: false,
            success: {
              ...state.login.post.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case DATA_DASHBOARD_LOGIN_FAILURE:
      return {
        ...state,
        login: {
          ...state.login,
          post: {
            ...state.login.post,
            loading: false,
            reset: false,
            success: {
              ...state.login.post.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };
    case DATA_DASHBOARD_LOGIN_RESET:
      return {
        ...state,
        login: {
          ...state.login.post,
          post: {
            ...INITIAL_STATE.login.post,
            reset: true,
          },
        },
      };
  }
  return state;
};

export default dataDashboardReducers;
