import axios from "axios";
import * as actionTypes from "./actionTypes";
import { auctionRef } from "../firebase";
import { message } from "antd";
import { child, get, getDatabase, off, onValue, ref } from "firebase/database";

const FETCH_BIDS_CONSUMER = "FETCH_BIDS_CONSUMER";

const getProjectListStart = () => {
  return {
    type: actionTypes.GET_PROJECT_LIST_START,
  };
};

export const addBidListener = (auction) => async (dispatch) => {
  let initialDataLoaded = false;
  const aRef = ref(auctionRef, "auction/" + auction);

  onValue(aRef, (snapshot) => {
    if (initialDataLoaded == true) {
      dispatch({
        type: FETCH_BIDS_CONSUMER,
      });
    }
  });

  onValue(
    aRef,
    (snapshot) => {
      initialDataLoaded = true;
    },
    { onlyOnce: true }
  );

  // const dbRef = ref(getDatabase());
  // get(child(dbRef, 'auction/' + auction)).then((snapshot) => {
  //   if (snapshot.exists()) {
  //     initialDataLoaded = true;
  //   } else {
  //
  //     initialDataLoaded = false;
  //   }
  //   initialDataLoaded = true;
  // }).catch((error) => {
  //
  // });

  // ref(auction).on("value", (snapshot) => {
  //   if (initialDataLoaded == true) {
  //     dispatch({
  //       type: FETCH_BIDS_CONSUMER,
  //     });
  //   }
  // });

  // ref(auction).once("value", (snapshot) => {
  //   initialDataLoaded = true;
  // });
};

export const removeBidListener = (auction) => async (dispatch) => {
  const aRef = ref(auctionRef, "auction/" + auction);
  // auctionRef.child(auction).off("value");
  off(aRef, "value");
};

const getProjectListSuccess = (projects) => {
  return {
    type: actionTypes.GET_PROJECT_LIST_SUCCESS,
    projects,
  };
};

const getProjectListFail = (error) => {
  return {
    type: actionTypes.GET_PROJECT_LIST_FAIL,
    error: error,
  };
};

const updateUserDetails = (userName, email, phoneNumber) => {
  return {
    type: actionTypes.UPDATE_USER_DETAILS,
    userName,
    email,
    phoneNumber,
  };
};

export const getProjects = (token) => {
  return (dispatch) => {
    dispatch(getProjectListStart());
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_BASE_URL}projects/`)
      .then((res) => {
        const projects = res.data.projects;
        const name = res.data.name;
        const email = res.data.name;
        const phoneNumber = res.data.phone_number;
        let userData = JSON.parse(localStorage.getItem("user"));
        userData = {
          ...userData,
          phoneNumber,
        };
        localStorage.setItem("user", JSON.stringify(userData));
        dispatch(getProjectListSuccess(projects));
        dispatch(updateUserDetails(name, email, phoneNumber));
      })
      .catch((err) => {
        dispatch(getProjectListFail(err));
      });
  };
};

export const getAdminProjects = (token) => {
  return (dispatch) => {
    dispatch(getProjectListStart());
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    axios
      .get("https://safearth-api.in/users/get_admin_projects/")
      .then((res) => {
        const projects = res.data.projects;
        dispatch(getProjectListSuccess(projects));
      })
      .catch((err) => {
        dispatch(getProjectListFail(err));
      });
  };
};

export const requestCallBack = (token) => {
  return (dispatch) => {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    axios
      .post("https://safearth-api.in/users/request_callback/")
      .then((res) => {})
      .catch((err) => {});
  };
};

const getProjectDetailStart = () => {
  return {
    type: actionTypes.GET_PROJECT_DETAIL_START,
  };
};

const getProjectDetailSuccess = (project) => {
  return {
    type: actionTypes.GET_PROJECT_DETAIL_SUCCESS,
    project,
  };
};

const getProjectDetailFail = (error) => {
  return {
    type: actionTypes.GET_PROJECT_DETAIL_FAIL,
    error: error,
  };
};

export const clearProjectDetail = (project) => {
  return {
    type: actionTypes.CLEAR_PROJECT_DETAIL,
  };
};

export const downloadReport = (token, id) => {
  return (dispatch) => {
    axios.defaults.headers = {
      "Content-Type": "blob",
      Authorization: `Token ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_BASE_URL}download_project_report/`, {
        params: {
          project_id: id,
        },
      })
      .then((res) => {
        const url = res.data.report;
        var win = window.open(url, "_blank");
        win.focus();
      })
      .catch((err) => {
        message.error(err.message);
      });
  };
};

export const downloadBoqReport = (token, id) => {
  return (dispatch) => {
    axios.defaults.headers = {
      "Content-Type": "blob",
      Authorization: `Token ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_BASE_URL}download_boq_report/`, {
        params: {
          project_id: id,
        },
      })
      .then((res) => {
        const url = res.data.report;
        var win = window.open(url, "_blank");
        win.focus();
      })
      .catch((err) => {
        message.error(err.message);
      });
  };
};

export const downloadVendorReport = (token, id) => {
  return (dispatch) => {
    axios.defaults.headers = {
      "Content-Type": "blob",
      Authorization: `Token ${token}`,
    };
    axios
      .get("https://safearth-api.in/users/download_project_report/", {
        params: {
          project_id: id,
        },
      })
      .then((res) => {
        const url = res.data.report;
        var win = window.open(url, "_blank");
        win.focus();
      })
      .catch((err) => {
        message.error(err.message);
      });
  };
};

export const getProjectDetail = (token, id) => {
  return (dispatch) => {
    dispatch(getProjectDetailStart());
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_BASE_URL}project_details/`, {
        params: {
          project_id: id,
        },
      })
      .then((res) => {
        let project = res.data.project;
        project.stage_reached = getActiveStep(project.stage_reached);
        localStorage.setItem("step",project.stage_reached)
        dispatch(getProjectDetailSuccess(project));
      })
      .catch((err) => {
        message.error(err.message);
        dispatch(getProjectDetailFail(err.message));
      });
  };
};

export const getAdminProjectDetail = (token, id) => {
  return (dispatch) => {
    dispatch(getProjectDetailStart());
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    axios
      .get("https://safearth-api.in/users/get_admin_project_detail/", {
        params: {
          project_id: id,
        },
      })
      .then((res) => {
        let project = res.data.project;
        dispatch(getProjectDetailSuccess(project));
      })
      .catch((err) => {
        dispatch(getProjectDetailFail());
      });
  };
};

const createProjectStart = () => {
  return {
    type: actionTypes.CREATE_PROJECT_START,
  };
};

const createProjectSuccess = () => {
  return {
    type: actionTypes.CREATE_PROJECT_SUCCESS,
  };
};

const createProjectFail = (error) => {
  return {
    type: actionTypes.CREATE_PROJECT_FAIL,
    error: error,
  };
};

export const reportIssue = (token, id, is_boq, issue_text) => {
  return (dispatch) => {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_BASE_URL}submit_issue/`, {
        params: {
          project_id: id,
          boq: is_boq,
          issue: issue_text,
        },
      })
      .then((res) => {
        message.success("Submitted Successfully!");
      })
      .catch((err) => {});
  };
};

export const approveReport = (token, id) => {
  return (dispatch) => {
    const form = new FormData(this);
    form.append("project_id", id);
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}approve_report`, form)
      .then((res) => {
        message.success("Approved Succesfully");
      })
      .catch((err) => {
        message.error("Something went wrong!");
      });
  };
};

export const approveBoq = (token, id) => {
  return (dispatch) => {
    const form = new FormData(this);
    form.append("project_id", id);
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    axios
      .post("https://safearth-api.in/users/approve_boq", form)
      .then((res) => {})
      .catch((err) => {});
  };
};

export const approveFinalBoq = (token, id) => {
  return (dispatch) => {
    const form = new FormData(this);
    form.append("project_id", id);
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    axios
      .post("https://safearth-api.in/users/approve_final_boq", form)
      .then((res) => {})
      .catch((res) => {});
  };
};

export const createProject = (token, details, history) => {
  return (dispatch) => {
    const {
      siteName,
      location,
      facility,
      discom,
      electricityBills,
      singleLineDiagram,
      arrayLayout,
      connectionNumber,
      electricalAnalysisFile,
      isNew,
      state,
      contractDemand,
    } = details;
    // documents api call
    const formData = new FormData();

    let filesData = [];
    let filesList = [...electricityBills, ...singleLineDiagram, ...arrayLayout];
    if (electricityBills?.length > 0) {
      electricityBills.map((i) =>
        filesData.push({ type: "Electricity Bill", title: i.name })
      );
    }
    if (singleLineDiagram?.length > 0) {
      singleLineDiagram.map((i) =>
        filesData.push({ type: "Single Line Diagram", title: i.name })
      );
    }
    if (arrayLayout?.length > 0) {
      arrayLayout.map((i) =>
        filesData.push({ type: "Rooftop Layout", title: i.name })
      );
    }

    formData.append("files_data", JSON.stringify(filesData));
    filesList.map((image) => formData.append("files", image));

    // create project api call
    const form = new FormData(this);
    form.append("project_id", 0);
    form.append("site_name", siteName);
    form.append("discom", discom);
    form.append("designation_site", location);
    form.append("is_new", isNew);
    form.append("contract_demand", contractDemand);
    form.append("state", state);

    form.append("location", `lat:${location?.lat},lng:${location?.lng}`);
    form.append("type_of_facility", facility);
    form.append("electrical_analysis_file", electricalAnalysisFile);
    form.append("connection_number", connectionNumber);

    dispatch(createProjectStart());
    axios.defaults.headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}create_project/`, form)
      .then(async (res) => {
        await dispatch(createProjectSuccess());
        await message.success("Project created successfully!");
        let val = res?.data?.project_id;
        if (res?.data?.project_id) {
          formData.append("project_id", res?.data?.project_id);
          Promise.resolve(dispatch(addProjectDocuments(formData, token))).then(
            (result) => {
              dispatch(getProjectDetail(token, val));

              localStorage.setItem("site_id", val);
              history.push({
                pathname: "/userProjectDashboard/electricity-mapping",
                id: val,
              });
            }
          );
        }
      })
      .catch((err) => {
        dispatch(createProjectFail(err));
        message.error("Error occurred while saving!");
      });
  };
};

export const deleteSiteDetailsFile = (data, token) => async (dispatch) => {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASE_URL}delete_site_detail_file/`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      }
    );
    if (result?.data?.status === "FAIL") {
      throw result.data;
    }

    message.success("Documents deleted successfully!");
    return result;
  } catch (e) {
    message.error("Error occurred while deleting documents!");

    return e;
  }
};

export const getSiteDetails = (project_id, token) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_SITE_DETAILS_LOADING,
    });
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}get_site_details?project_id=${project_id}`
      )
      .then((res) => {
        dispatch({
          type: actionTypes.GET_SITE_DETAILS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.GET_SITE_DETAILS_FAIL,
        });
      });
  };
};

const getActiveStep = (stage) => {
  switch (stage) {
    case "1":
      return 0;
    case "2":
    case "3":
      return 1;
    case "4":
      return 2;
    case "5":
      return 3;
    case "6":
      return 4;
    default:
      return 4;
  }
};

export const contactUs = (details) => {
  return (dispatch) => {
    const { company, phone, name, email, comment } = details;
    axios
      .get("https://safearth-api.in/users/contact_us", {
        params: {
          company: company,
          phone_number: phone,
          name: name,
          email: email,
          type: comment,
        },
      })
      .then((res) => {})
      .catch((err) => {});
  };
};

export const submitSiteVisitDetails = (siteVisitInfo) => {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}book_site`, {
        params: siteVisitInfo,
      })
      .then((res) => {
        message.success("Details submitted successfully!");
        return res;
      })
      .catch((err) => {
        message.error(err.message);
        return err;
      });
  };
};

export const calculatorLead = (details) => {
  return (dispatch) => {
    const { pincode, rooftop, tariff, Consumption, sanctioned_load, phone } =
      details;
    axios
      .get("https://safearth-api.in/users/calculator_lead", {
        params: {
          pincode: pincode,
          rooftop_space: rooftop,
          tariff: tariff,
          consumption: Consumption,
          sanctioned_load: sanctioned_load,
          phone_number: phone,
        },
      })
      .then((res) => {})
      .catch((err) => {});
  };
};

export const getVendorDetails = (project_id, token) => {
  return (dispatch) => {
    dispatch(getVendorDetailsLoading());
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}get_vendors?project_id=${project_id}`
      )
      .then((res) => {
        let vendors = res.data.vendors;
        let suggested_vendors = res.data.suggested_vendors;
        dispatch(getVendorDetailsSuccess(vendors, suggested_vendors));
      })
      .catch((e) => {
        dispatch(getVendorDetailsFail());
      });
  };
};

const getVendorDetailsLoading = () => {
  return {
    type: actionTypes.GET_VENDOR_DETAILS_LOADING,
  };
};

const getVendorDetailsSuccess = (vendors, suggested_vendors) => {
  return {
    type: actionTypes.GET_VENDOR_DETAILS_SUCCESS,
    vendors,
    suggested_vendors,
    loading: false,
  };
};

const getVendorDetailsFail = () => {
  return {
    type: actionTypes.GET_VENDOR_DETAILS_FAIL,
  };
};

export const getAllVendors = (token) => {
  return (dispatch) => {
    dispatch(getAllVendorsLoading());
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_BASE_URL}get_all_vendors`)
      .then((res) => {
        let myVendors = res.data.my_vendors;
        let recommended_vendors = res.data.recommended_vendor_list;
        dispatch(getAllVendorsSuccess(myVendors, recommended_vendors));
        return res;
      })
      .catch((e) => {
        dispatch(getAllVendorsFail());
      });
  };
};

export const getAllVendorsWithEligibilityCriteria =
  (token, eligibilty_criteria) => async (dispatch) => {
    await message.loading("Loading...");
    await dispatch(getAllVendorsLoading(eligibilty_criteria));

    try {
      const result = await axios.get(
        `${process.env.REACT_APP_BASE_URL}get_all_vendors`,
        {
          params: {
            ...eligibilty_criteria,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );

      if (result.data.status === "FAIL") {
        message.error(result.data.error);
        return result.data;
      }

      if (result.status === 200) {
        let myVendors = result.data.my_vendors;
        let recommended_vendors = result.data.recommended_vendor_list;
        dispatch(getAllVendorsSuccess(myVendors, recommended_vendors));
        return result;
      }
    } catch (err) {
      message.error(err.message);
      return;
    }
  };

const getAllVendorsLoading = () => {
  return {
    type: actionTypes.GET_ALL_VENDORS_LOADING,
  };
};

const getAllVendorsSuccess = (myVendors, recommended_vendors) => {
  return {
    type: actionTypes.GET_ALL_VENDORS_SUCCESS,
    myVendors,
    recommended_vendors,
    loading: false,
  };
};

const getAllVendorsFail = () => {
  return {
    type: actionTypes.GET_ALL_VENDORS_FAIL,
  };
};

export const shortlistVendors = (project_id, vendors, token) => {
  const form = new FormData();
  form.append("project_id", project_id);
  form.append("vendors", vendors);
  return (dispatch) => {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}shortlist`, form)
      .then((res) => {
        dispatch(shortlistVendorsSuccess(true));
        message.success("Vendors shortlisted sucessfully!");
      });
  };
};

const shortlistVendorsSuccess = (success) => {
  return {
    type: actionTypes.SHORTLIST_VENDORS,
    success,
  };
};

export const finalizeMeeting = (project_id, vendors, token) => {
  const form = new FormData();
  form.append("project_id", project_id);
  form.append("vendors", vendors);

  return (dispatch) => {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}finalize_meeting/`, form)
      .then((res) => {
        dispatch(finalizeMeetingSuccess(true));
      })
      .catch((err) => {});
  };
};

const finalizeMeetingSuccess = (success) => {
  return {
    type: actionTypes.FINALIZE_MEETING,
    success,
  };
};

export const addProjectDocuments = (data, token, id) => async (dispatch) => {
  dispatch({ type: actionTypes.ADD_PROJECT_DOCUMENTS_LOADING });

  await message.loading("Uploading...", 1);

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASE_URL}add_project_documents/`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      }
    );
    if (result?.data?.status === "FAIL") {
      throw result.data;
    }
    dispatch({
      type: actionTypes.ADD_PROJECT_DOCUMENTS_SUCCESS,
      payload: result.data,
    });

    await message.success("Documents uploaded successfully!", 1);
    return result;
  } catch (e) {
    if (e?.status === "FAIL") {
      message.error(e.response, 2);
    } else {
      message.error("Error occurred while uploading documents!", 1);
    }
    dispatch({ type: actionTypes.ADD_PROJECT_DOCUMENTS_FAILURE, payload: e });
    return e;
  }
};

export const deleteProjectDocuments = (data, token) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_PROJECT_DOCUMENTS_LOADING });

  await message.loading("Loading...", 1);

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASE_URL}delete_project_documents/`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      }
    );
    if (result?.data?.status === "FAIL") {
      throw result.data;
    }
    dispatch({
      type: actionTypes.DELETE_PROJECT_DOCUMENTS_SUCCESS,
      payload: result.data,
    });
    await message.success("Documents deleted successfully!", 1);
    return result;
  } catch (e) {
    if (e?.status === "FAIL") {
      message.error(e.response, 2);
    } else {
      message.error("Error occurred while deleting documents!", 1);
    }
    dispatch({
      type: actionTypes.DELETE_PROJECT_DOCUMENTS_FAILURE,
      payload: e,
    });
    return e;
  }
};
