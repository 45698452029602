const CryptoJS = require("crypto-js");
const SecureStorage = require('secure-web-storage');

const SECRET_KEY = 'dkjgdhjdgjhsgjsbjhevujdgiyhbuicutcukabkjbk';

const secureStorage = new SecureStorage(localStorage, {

    hash: function hash(key) {
        key = CryptoJS.SHA256(key, SECRET_KEY);

        return key.toString();
    },
    encrypt: function encrypt(data) {
        data = CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY);

        data = data.toString();

        return data;
    },
    decrypt: function decrypt(data) {
        data = CryptoJS.AES.decrypt(data, SECRET_KEY);

        data = data.toString(CryptoJS.enc.Utf8);

        return data;
    }
});

// there is no need to stringify/parse you objects before and after storing.

const setItem = (dataKey, data) => {
    secureStorage.setItem(dataKey, data);
};
// stores in localStorage like:
// key => value
// "ad36d572..." => "w1svi6n..."

const getItem = (dataKey) => {
    return secureStorage.getItem(dataKey);
};
// returns { secret: 'data' }

const removeItem = (dataKey) => {
    secureStorage.removeItem(dataKey);
};
// removes the entry 'data'

// secureStorage.key(id)
// returns the hashed version of the key you passed into setItem with the given id.

const clearItems = () => {
    secureStorage.clear();
};
// clears all data in the underlining sessionStorage/localStorage.

export {
    secureStorage,
    setItem,
    getItem,
    removeItem,
    clearItems
};
