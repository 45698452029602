import React from "react";
import { Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { currencySymbols } from "./constants";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";

/**
 * Truncates the string according to the provided length.
 * Wraps the string with an Antd Tooltip component if the length of the string exceeds the required length.
 * @param {String} text The string to perform the truncation on.
 * @param {number} length The total required length of the string after truncation.
 * @return {String} The truncated string wrapped with an Antd Tooltip component
 * OR
 * The string without any truncation if the length of the string is less than the required length.
 */
export const truncateText = (text, length) => {
  if (text !== undefined && text !== null && text.length > length) {
    return (
      <Tooltip title={text}>{text.trim().slice(0, length) + "... "}</Tooltip>
    );
  } else {
    return text;
  }
};

export const isObjEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const infoIconTooltip = (tooltipTitle, placement) => {
  return (
    <Tooltip title={tooltipTitle} placement={placement || "bottom"}>
      <QuestionCircleOutlined className="info-icon" />
    </Tooltip>
  );
};

/**
 * Checks if an element is in viewport

 * @param {Element} viewElement - Element in question
 */
export const isInViewport = (viewElement) => {
  //get how much pixels left to scrolling our ReactElement
  const top = viewElement.getBoundingClientRect().top;

  //here we check if element top reference is on the top of viewport
  /*
   * If the value is positive then top of element is below the top of viewport
   * If the value is zero then top of element is on the top of viewport
   * If the value is negative then top of element is above the top of viewport
   * */
  if (top >= 0 && top <= window.innerHeight) {
    //Element is in view or above the viewport
    return true;
  } else {
    //Element is outside view
    return false;
  }
};

/**
 * Checks if the page is the current page

 * @param {URL} pathname - Pathname of the current page in question
 */
export const isCurrentPage = (pathname) =>
  window.location.pathname?.includes(pathname);

/**
 * Waits and scrolls the element to the specified distance

 * @param {Element} element - Element to scroll
 * @param {(vertical|horizontal)} direction - Scroll Direction("vertical" or "horizontal")
 * @param {number} scrollDistance - Distance to scroll the element
 * @param {number} pause - Time to execute the scroll in milliseconds.
 */
export function waitAndScroll(element, direction, scrollDistance, pause) {
  let interval;
  if (direction === "vertical") {
    interval = setInterval(function () {
      var scrolled = element?.scrollTop;
      var scroll_size = element?.scrollHeight;
      var scroll_remaining = scroll_size - scrolled;

      if (scroll_remaining <= window.innerHeight) {
        clearInterval(interval);
      } else {
        element.scrollBy(scrollDistance, 0);
      }
    }, pause);
  } else if (direction === "horizontal") {
    interval = setInterval(function () {
      var scrolled = element?.scrollLeft;
      var scroll_size = element?.scrollWidth;
      var scroll_remaining = scroll_size - scrolled;

      if (scroll_remaining <= window.innerWidth) {
        clearInterval(interval);
      } else {
        element.scrollBy(scrollDistance, 0);
      }
    }, pause);
  }

  return interval;
}

/**
 * Validates an email address using regex
 * Returns true if email is valid, else false

 * @param {string} email - Pathname of the current page in question
 * @returns {Boolean}
 */
export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

/**
 * Returns the currency symbol for the respective country code

 * @param countryCode - Alpha-3 country code as per ISO 4217
 * @returns {String} pathname - Pathname of the current page in question
 */
export const getCurrencySymbol = (countryCode) => {
  return currencySymbols[countryCode];
};

/**
 * Validates an email address using regex.
 * Accepted file extensions: .jpg | .jpeg | .png | .gif

 * @param {String} fileName - File name including file extension
 * @returns {Boolean} true if file is an image, else false
 */
export const isImage = (fileName) => {
  return fileName.match(/\.(jpg|jpeg|png|gif)$/i);
};

/**
 * Checks whether the given value is a whole number.

 * @param {Number} value - value to check
 * @returns {Boolean} true if value is a whole number, else false
 */
export const isWholeNumber = (value) => {
  return value % 1 === 0;
};

/**
 * Checks whether the given value is a numeric decimal value.

 * @param {String} str - value to check
 * @returns {Boolean} true if value is a decimal number, else false
 */
export const isNumeric = (str) => {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
};

/**
 * Returns the value of the specified query param
 * @param {String} search - Search string from location object
 * @param {String} queryParamName - Name of the query param required
 */
export function getQueryParamValue(search, queryParamName) {
  return new URLSearchParams(search).get(queryParamName);
}

/**
 * Capitalizes the first letter of the given string
 * @param {String} string - String to capitalize
 * @returns {String} The complete string with the first letter capitalized
 */
export function capitalizeString(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Checks whether the given variable is defined or not.
 *
 * Checks for NaN, NaN as string, null, empty string and undefined types.
 * @param value - Variable to check
 * @returns {Boolean} True if value is defined, else false
 */
export function isDefined(value) {
  return (
    value &&
    value !== null &&
    value !== undefined &&
    value !== NaN &&
    value !== "" &&
    value !== "NaN"
  );
}

/**
 * Opens the given file url in a new tab
 * @param url - The file URL
 */
export function openFileInNewTab(url) {
  if (url) {
    var win = window.open(url, "_blank");
    return win.focus();
  }
}

/**
 * Returns the base URL for the respective platform.
 * @param {String} toPlatform
 * The name of the platform.
 *
 * Accepts only three values: "auction" for Project Marketplace, "pmt" for Solarfow and "ecom" for Component Marketplace.
 * @returns {String}
 */
export const getBaseUrl = (toPlatform) => {
  const AUCTION_BASE_URL = process.env.REACT_APP_BASE_URL;
  const SOLARFLOW_BASE_URL = process.env.REACT_APP_SOLARFLOW_BASE_URL;
  const ECOM_BASE_URL = process.env.REACT_APP_ECOM_BASE_URL;

  return toPlatform === "pmt"
    ? SOLARFLOW_BASE_URL
    : toPlatform === "ecom"
    ? ECOM_BASE_URL
    : AUCTION_BASE_URL;
};

/**
 * Returns the relative time in words.
 * @param {String} eventDate
 * @param {String} format
 * @returns {String} The relative time.
 */
export const getRelativeTime = (eventDate, format) => {
  let now = moment();
  let date = moment(eventDate, format);
  return now.from(date, true);
};

/**
 * Extracts the date from the given timestamp.
 * @param {String} timestamp The timestamp containing the date and time.
 * @returns {String} The extracted date. Eg: January 1st, 2023.
 */
export const getDateFromTimestamp = (timestamp) => {
  const dateFns = new DateFnsUtils();

  return (
    dateFns.format(new Date(timestamp), dateFns.dateFormat) +
    ", " +
    dateFns.getYearText(new Date(timestamp))
  );
};

/**
 * Extracts the time from the given timestamp.
 * @param {String} timestamp The timestamp containing the date and time.
 * @returns {String} The extracted time in 12 hour format. Eg: 04:57 PM.
 */
export const getTimeFromTimestamp = (timestamp) => {
  const dateFns = new DateFnsUtils();

  return dateFns.format(new Date(timestamp), dateFns.time12hFormat);
};

/**
 * Returns the current financial year.
 * @returns {String} The string formatted financial year. Eg: 20xx-20xx
 */
export const getCurrentFinancialYear = () => {
  var fiscalyear = "";
  var today = new Date();
  //Month index starts from 0. Hence month 3 is April
  if (today.getMonth() < 3) {
    fiscalyear = today.getFullYear() - 1 + "-" + today.getFullYear();
  } else {
    fiscalyear = today.getFullYear() + "-" + (today.getFullYear() + 1);
  }
  return fiscalyear;
};
