import { GET_JOBS_FAILURE, GET_JOBS_LOADING, GET_JOBS_SUCCESS } from "../../actions/actionTypes";

const INITIAL_STATE = {
    jobs: {
        get: {
            loading: false,
            reset: false,
            success: {
                ok: false,
                data: null,
            },
            failure: {
                error: false,
                message: '',
            },
        }
    }
};

const jobsReducers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_JOBS_LOADING:
            return {
                ...state,
                jobs: {
                    ...state.jobs,
                    get: {
                        ...state.jobs.get,
                        loading: true,
                        reset: false,
                        success: {
                            ...state.jobs.get.success,
                            ok: false,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
            };
        case GET_JOBS_SUCCESS:
            return {
                ...state,
                jobs: {
                    ...state.jobs,
                    get: {
                        ...state.jobs.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.jobs.get.success,
                            ok: true,
                            data: action.payload,
                        },
                        failure: {
                            error: false,
                            message: '',
                        },
                    },
                },
            };
        case GET_JOBS_FAILURE:
            return {
                ...state,
                jobs: {
                    ...state.jobs,
                    get: {
                        ...state.jobs.get,
                        loading: false,
                        reset: false,
                        success: {
                            ...state.jobs.get.success,
                            ok: false,
                            data: null,
                        },
                        failure: {
                            error: true,
                            message: action.payload.message,
                        },
                    },
                },
            };
    }
    return state;
}

export default jobsReducers;