import { initializeApp } from 'firebase/app';
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDPtE0P321kGL5WQfB5uFcpCYxCVK-9pEc",
  authDomain: "safearth-auction.firebaseapp.com",
  databaseURL: "https://safearth-auction.firebaseio.com",
  projectId: "safearth-auction",
  storageBucket: "safearth-auction.appspot.com",
  messagingSenderId: "94514122769",
  appId: "1:94514122769:web:ec577fa4a80f317c",
};

const app = initializeApp(firebaseConfig);

const databaseRef = getDatabase(app);
export const auctionRef = databaseRef;
