import axios from "axios";
import { getBaseUrl } from "../../Components/util";
import { setItem } from "../../SecureStorage";

export const SSO_AUTHENTICATE_LOADING = "SSO_AUTHENTICATE_LOADING";
export const SSO_AUTHENTICATE_SUCCESS = "SSO_AUTHENTICATE_SUCCESS";
export const SSO_AUTHENTICATE_FAILURE = "SSO_AUTHENTICATE_FAILURE";
export const SSO_AUTHENTICATE_RESET = "SSO_AUTHENTICATE_RESET";

/**
 * Authenticates Single Sign-On.
 *
 * Based on the type of platform, it sends a post request to the respective server for authentication.
 * @param {Object} user The details of the user.
 * @param {String} token The unique token of the user.
 * @param {String} toPlatform
 * The name of the platform from where the token needs to be retrieved.
 *
 * Accepts only two values: "pmt" for Solarfow and "ecom" for Component Marketplace.
 * @returns
 */
export const authenticateSSO =
  (user, token, toPlatform, password, from) => async (dispatch) => {
    dispatch({
      type: SSO_AUTHENTICATE_LOADING,
      payload: null,
    });

    const formData = new FormData();
    formData.append("email", user?.username);
    formData.append("from_platform", from ? from : "pmt");
    formData.append("company_name", user?.company_name);
    formData.append("name", user?.username);
    formData.append("password", password);

    if (toPlatform === "ecom") {
      formData.append("token", token);
      formData.append("is_buyer", true);
    } else if (toPlatform === "pmt") {
      formData.append("token", token);
      formData.append("is_customer", user?.is_consumer);
    } else if (toPlatform === "auction") {
      formData.append("token", user?.pmtToken ? user.pmtToken : token);
      if (user?.isConsumer) {
        formData.append("is_consumer", true);
        formData.append("is_vendor", false);
      } else {
        formData.append("is_consumer", false);
        formData.append("is_vendor", true);
      }

      formData.append("website", user?.website);
      formData.append("phone", user?.phone);
    }

    const BASE_URL = getBaseUrl(toPlatform);

    try {
      const result = await axios.post(
        `${BASE_URL}sso_authenticate/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (result?.data?.status?.toString()?.toUpperCase() === "FAIL") {
        throw result.data;
      }
      localStorage.setItem(`${toPlatform}Token`, result?.data?.token);
      localStorage.setItem(`is_new_${toPlatform}_user`, result?.data?.is_new);
      setItem("password", password);
      const userType = result?.data?.is_buyer ? "isBuyer" : "isSeller";
      if (toPlatform === "ecom") {
        localStorage.setItem("ecom_user_type", userType);
        dispatch({
          type: SSO_AUTHENTICATE_SUCCESS,
          payload: {
            [toPlatform]: {
              token: result?.data?.token,
              is_new: result?.data?.is_new,
              userType: userType,
            },
          },
        });
      } else {
        if (result?.data?.department) {
          localStorage.setItem("department", result?.data?.department);
        }
        dispatch({
          type: SSO_AUTHENTICATE_SUCCESS,
          payload: {
            [toPlatform]: {
              token: result?.data?.token,
              is_new: result?.data?.is_new,
              department: result?.data?.department,
            },
          },
        });
      }
      return result;
    } catch (e) {
      if (e?.status?.toString()?.toUpperCase() === "FAIL") {
        dispatch({
          type: SSO_AUTHENTICATE_FAILURE,
          payload: e.response,
        });
      } else {
        dispatch({ type: SSO_AUTHENTICATE_FAILURE, payload: e });
      }
      return e;
    }
  };

/**
 * Handler function to authenticate Single Sign-On.
 * @param {Object} user The details of the user.
 * @param {String} token The unique token of the user.
 * @param {Function} dispatch The dispatch function's reference from the Redux store.
 * @param {String} password The user's password.
 */
export const ssoAuthenticator = (user, token, dispatch, password) => {
  Promise.resolve(
    dispatch(authenticateSSO(user, token, "pmt", password, "auction"))
  ).then((result) => {
    if (result?.status === 200) {
      Promise.resolve(
        dispatch(authenticateSSO(user, token, "ecom", password, "auction"))
      ).then((result) => {
        //success
      });
    }
  });
};
