import React from "react";
import { useLocation } from "react-router-dom";

const PMTReRoute = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const email = user?.username;
  const department = localStorage.getItem("department");

  const location = useLocation();
  const query = new URLSearchParams(location?.search);

  localStorage.removeItem("redirectFrom");

  const path = window.location.pathname?.replace("/pmt/", "");
  let url = `${
    process.env.REACT_APP_SOLARFLOW_SITE_BASE_URL
  }?pmtToken=${localStorage.getItem(
    "pmtToken"
  )}&path=${path}&is_new=${localStorage.getItem(
    "is_new_pmt_user"
  )}&email=${email}&auctionToken=${user?.token}&redirectFrom=auction`;

  if (path?.includes("project-dashboard")) {
    // const projectId = props.location?.state?.projectId;
    const projectId = query.get("projectId");
    if (projectId) {
      url = url + `&projectId=${projectId}`;
    } else {
      window.location.replace("/dashboard");
    }
  }

  // const requestFinance = props.location?.state?.requestFinance;
  const requestFinance = query.get("requestFinance");
  if (requestFinance) {
    url = url + `&requestFinance=true`;
  }

  // const createNewProposal = props.location?.state?.createNewProposal;
  const createNewProposal = query.get("createNewProposal");
  if (createNewProposal) {
    url = url + `&createNewProposal=true`;
  }

  if (user?.first_login) {
    url = url + "&first_login=true";
  }

  if (department) {
    url = url + "&department=" + department;
  }

  const isConsumer = query.get("is_consumer");
  if (isConsumer) {
    url = url + `&is_consumer=true`;
  }

  return window.location.replace(url);
};

export default PMTReRoute;
