import React from "react";

export const statesAndUTsList = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttarakhand",
  "Uttar Pradesh",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Puducherry",
];
export const siteStates = [
  "ANDAMAN and NICOBAR ISLANDS",
  "ANDHRA PRADESH",
  "ASSAM",
  "BIHAR",
  "CHANDIGARH",
  "CHHATTISGARH",
  "GOA",
  "GUJARAT",
  "HARYANA",
  "HIMACHAL PRADESH",
  "JAMMU and KASHMIR",
  "JHARKHAND",
  "KARNATAKA",
  "KERALA",
  "LADAKH",
  "LAKSHADWEEP",
  "MADHYA PRADESH",
  "MAHARASHTRA",
  "MANIPUR",
  "MEGHALAYA",
  "MIZORAM",
  "NAGALAND",
  "NCT OF DELHI",
  "ORISSA",
  "PUDUCHERRY",
  "PUNJAB",
  "RAJASTHAN",
  "SIKKIM",
  "TAMIL NADU",
  "TELANGANA",
  "THE DADRA AND NAGAR HAVELI AND DAMAN AND DIU",
  "TRIPURA",
  "UTTAR PRADESH",
  "UTTARAKHAND",
  "WEST BENGAL",
];

export const discomValuesHandler = (state) => {
  if (state === "ANDAMAN and NICOBAR ISLANDS") {
    return ["Electricity Deptt. of Andaman & Nicobar Islands"];
  } else if (state === "ANDHRA PRADESH") {
    return [
      "Eastern Power Distribution Company of A P Ltd",
      "Southern Power Distribution Company of AP Limited",
    ];
  } else if (state === "ASSAM") {
    return ["Assam Power Distribution Company Limited"];
  } else if (state === "BIHAR") {
    return [
      "North Bihar Power Distribution Company Limited",
      "South Bihar Power Distribution Company Limited",
    ];
  } else if (state === "CHANDIGARH") {
    return ["Electricity Deptt. of Chandigarh"];
  } else if (state === "CHHATTISGARH") {
    return ["C S P D C L"];
  } else if (state === "GOA") {
    return ["Electricity Deptt. of Goa"];
  } else if (state === "GUJARAT") {
    return [
      "Madhya Gujarat VIJ Company Ltd.",
      "Dakshin Gujarat Vij Co.Ltd (DGVCL)",
      "Paschim Gujarat Vij Co. Limited",
      "Uttar Gujarat Vij Company Limited",
      "Torrent Power Limited - Surat",
      "Torrent Power Limited - Ahmedabad",
    ];
  } else if (state === "HARYANA") {
    return [
      "Uttar Haryana Bijli Vitran Nigam Limited",
      "Dakshin Haryana Bijli Vitran Nigam Limited",
    ];
  } else if (state === "HIMACHAL PRADESH") {
    return ["HPSEB Limited"];
  } else if (state === "JAMMU and KASHMIR") {
    return ["Power Development Deptt. of J & K"];
  } else if (state === "JHARKHAND") {
    return [
      "Jharkhand Bijli Vitran Nigam Limited",
      "TATA STEEL UTILITIES AND INFRASTRUCTURE SERVICES LIMITED",
    ];
  } else if (state === "KARNATAKA") {
    return [
      "Bangalore Electricity Supply Company Ltd. (BESCOM)",
      "Mangalore Electricity Supply Company Limited",
      "Hubli Electricity Supply Company Ltd. (HESCOM)",
      "Gulbarga Electricity Supply Company Ltd. (GESCOM)",
      "Chamundeshwari Electricity Supply Company Ltd. (CHESCOM)",
    ];
  } else if (state === "KERALA") {
    return ["Kerala State Electricity Board"];
  } else if (state === "LADAKH") {
    return ["Ladakh Power Development Department"];
  } else if (state === "LAKSHADWEEP") {
    return ["Electricity Deptt. of Lakshadweep"];
  } else if (state === "MADHYA PRADESH") {
    return [
      "M.P. Poorv Kshetra Vidyut Vitran Co",
      "M.P.Madhya Kshetra Vidyut Vitran Co",
      "M.P.Paschim Kshetra Vidyut Vitran Co",
    ];
  } else if (state === "MAHARASHTRA") {
    return [
      "Maharashtra State Electricity Distribution Co. Ltd",
      "Brihanmumbai Electric Supply & Transport (BEST)",
      "Tata Power Co. Ltd",
      "Adani Electricity Mumbai Limited",
    ];
  } else if (state === "MANIPUR") {
    return ["Manipur State Power Distribution Company Limited (MSPDCL)"];
  } else if (state === "MEGHALAYA") {
    return ["Meghalaya Power Distribution Corporation Ltd"];
  } else if (state === "MIZORAM") {
    return ["Power & Electricity Department, Government of Mizoram"];
  } else if (state === "NAGALAND") {
    return ["NAGALAND"];
  } else if (state === "NCT OF DELHI") {
    return [
      "BSES Rajdhani Power Limited",
      "BSES Yamuna Power Limited",
      "Tata Power Delhi Distribution Limited",
      "NEW DELHI MUNICIPAL COUNCIL",
      "MES Delhi Cantt",
    ];
  } else if (state === "ORISSA") {
    return [
      "TPCODL, TP Central Odisha Distribution Ltd",
      "TPNODL, TP Northern Odisha Distribution Limited",
      "TPWODL, TP Western Odisha Distribution Limited",
      "TPSODL, TP Southern Odisha Distribution Limited",
    ];
  } else if (state === "PUDUCHERRY") {
    return ["Electricity Department, Puducherry"];
  } else if (state === "PUNJAB") {
    return ["Punjab State Power Corporation Ltd"];
  } else if (state === "RAJASTHAN") {
    return [
      "Jaipur Vidyut Vitran Nigam Ltd",
      "Ajmer Vidyut Vitran Nigam Ltd",
      "Jodhpur Vidyut Vitran Nigam Ltd",
    ];
  } else if (state === "SIKKIM") {
    return ["Energy & Power Department, Government of Sikkim"];
  } else if (state === "TAMIL NADU") {
    return ["Tamil Nadu Generation and Distribution Company Limited"];
  } else if (state === "TELANGANA") {
    return [
      "Southern Power Distribution Company of Telangana Limited",
      "Northern Power Distribution Company of Telangana Limited",
    ];
  } else if (state === "THE DADRA AND NAGAR HAVELI AND DAMAN AND DIU") {
    return [
      "Electricity Deptt. of Daman & Diu",
      "Electricity Deptt. of Dadar & Nagar Haveli",
    ];
  } else if (state === "TRIPURA") {
    return ["Tripura State Electricity Corporation Limited"];
  } else if (state === "UTTAR PRADESH") {
    return [
      "Poorvanchal Vidyut Vitran Nigam Ltd",
      "Pashchimanchal Vidyut Vitran Nigam Ltd",
      "Madhyanchal Vidyut Vitran Nigam Ltd",
      "Dakshinanchal Vidyut Vitran Nigam Ltd",
      "Kanpur Electric Supply Company Ltd",
      "Noida Power Company Limited",
      "Torrent Power Limited",
    ];
  } else if (state === "UTTARAKHAND") {
    return ["Uttarakhand Power Corporation Ltd"];
  } else if (state === "WEST BENGAL") {
    return ["WBSEDCL"];
  }
};

export const facilities = [
  "Manufacturing Unit",
  "Warehouse",
  "Hospital",
  "Hotel",
  "Office Building",
  "Residential Tower",
  "Home",
];
export const discoms = [
  "Bangalore Electricity Supply Company Limited | Karnataka",
  "Chamundeshwari Electricity Supply Company Limited | Karnataka",
  "Mangalore Electricity Supply Company Limited | Karnataka",
];

export const projectTypes = [
  "Residential",
  "Commercial",
  "Industrial",
  "Open Access",
];

export const blueGradientBox = (
  <div
    className="blue-gradient-box"
    style={{
      backgroundImage:
        "linear-gradient(102deg, #3d87f1 46%, #b5d1fa 99%, #fff 127%)",
      height: "70px",
      width: "100%",
      // position: "absolute",
      bottom: 0,
      left: 0,
      boxShadow: "0 18px 35px 0 rgba(61, 135, 241, 0.16)",
    }}
  />
);

export const countriesList = [
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas (the)",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia (Plurinational State of)",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory (the)",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cayman Islands (the)",
  "Central African Republic (the)",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands (the)",
  "Colombia",
  "Comoros (the)",
  "Congo (the Democratic Republic of the)",
  "Congo (the)",
  "Cook Islands (the)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Côte d'Ivoire",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic (the)",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands (the) [Malvinas]",
  "Faroe Islands (the)",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories (the)",
  "Gabon",
  "Gambia (the)",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (the)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  // "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (the Democratic People's Republic of)",
  "Korea (the Republic of)",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic (the)",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands (the)",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia (Federated States of)",
  "Moldova (the Republic of)",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands (the)",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger (the)",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands (the)",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines (the)",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of North Macedonia",
  "Romania",
  "Russian Federation (the)",
  "Rwanda",
  "Réunion",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan (the)",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan (Province of China)",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands (the)",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates (the)",
  "United Kingdom of Great Britain and Northern Ireland (the)",
  "United States Minor Outlying Islands (the)",
  "United States of America (the)",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela (Bolivarian Republic of)",
  "Viet Nam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const scopeOfWork = [
  "Technical Feasibility of the Project",
  "Finalization of Technical and Commercial Terms of the Project OR Preparation of RFQ",
  "Selection of EPCs/Developers",
  "Online Bidding of Cost Discovery",
  "Financing",
  "Management of Project using Project Management Software",
  "Final Design and Engineering of Solar PV System",
  "Review of Engineering drawings",
  "Site Handover for Construction to Vendor",
  "Procurement and Supply of All system components and tools for Installation and Commissioning of Solar PV System",
  "Installation, Testing and Commissioning of Solar PV System",
  "Technical Audit of the Installation of Solar PV system",
  "Clear Estimation of Civil Work",
  "O&M",
  "Aesthetic Cabling for Solar PV system",
  "Water Connection near the Roof for cleaning of Modules",
  "Electricity Connection near Roof during Installation",
  "Internet connection for Remote Monitoring",
  "Spares of system components subject to Warranty",
  "Spare feeder",
  "Access Ladders",
  "CEIG and All Other legal Approvals",
  "Removing of Shading objects(Trimming / Removal of trees or antennas)",
];

/**
 * @key Alpha-3 Currency Code
 * @value Currency Symbol
 */
export const currencySymbols = {
  INR: "₹",
  USD: "$",
};

export const auctionTypesList = ["InC", "InC + BoS", "EPC"];

export const jobDepartmentsList = [
  "Engineering",
  "Operations",
  "Business Development",
  "Product",
  "Marketing",
  "HR",
];

export const viewModesList = [
  "Activation Status",
  "Profile Complete",
  "User Type",
  "Auction Invitations Received",
  "Auctions Participated",
  "Products in Use",
  "NPS Score",
  "Activity Level",
  "Mobile App Download",
  "Last Bid Date",
];

export const countriesByRegion = {
  Africa: [
    "Algeria",
    "Angola",
    "Benin",
    "Botswana",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cameroon",
    "Central African Republic",
    "Chad",
    "Comoros",
    "Democratic Republic of the Congo",
    "Djibouti",
    "Egypt",
    "Equatorial Guinea",
    "Eritrea",
    "Eswatini",
    "Ethiopia",
    "Gabon",
    "Gambia",
    "Ghana",
    "Guinea",
    "Guinea-Bissau",
    "Ivory Coast",
    "Kenya",
    "Lesotho",
    "Liberia",
    "Libya",
    "Madagascar",
    "Malawi",
    "Mali",
    "Mauritania",
    "Mauritius",
    "Morocco",
    "Mozambique",
    "Namibia",
    "Niger",
    "Nigeria",
    "Rwanda",
    "Sao Tome and Principe",
    "Senegal",
    "Seychelles",
    "Sierra Leone",
    "Somalia",
    "South Africa",
    "South Sudan",
    "Sudan",
    "Tanzania",
    "Togo",
    "Tunisia",
    "Uganda",
    "Zambia",
    "Zimbabwe",
  ],
  "Asia Pacific": [
    "Australia",
    "New Zealand",
    "Papua New Guinea",
    "Fiji",
    "Solomon Islands",
    "Vanuatu",
    "Samoa",
    "Tonga",
    "Kiribati",
    "Tuvalu",
    "Nauru",
    "Marshall Islands",
    "Micronesia",
    "Palau",
    "Federated States of Micronesia",
    "Cook Islands",
    "Niue",
    "American Samoa",
    "Guam",
    "Northern Mariana Islands",
  ],
  Europe: [
    "Albania",
    "Andorra",
    "Austria",
    "Belarus",
    "Belgium",
    "Bosnia and Herzegovina",
    "Bulgaria",
    "Croatia",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Estonia",
    "Finland",
    "France",
    "Germany",
    "Greece",
    "Hungary",
    "Iceland",
    "Ireland",
    "Italy",
    "Kosovo",
    "Latvia",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Malta",
    "Moldova",
    "Monaco",
    "Montenegro",
    "Netherlands",
    "North Macedonia",
    "Norway",
    "Poland",
    "Portugal",
    "Romania",
    "Russia",
    "San Marino",
    "Serbia",
    "Slovakia",
    "Slovenia",
    "Spain",
    "Sweden",
    "Switzerland",
    "Ukraine",
    "United Kingdom",
    "Vatican City",
  ],
  "North America": [
    "United States of America",
    "Canada",
    "Mexico",
    "Guatemala",
    "Belize",
    "El Salvador",
    "Honduras",
    "Nicaragua",
    "Costa Rica",
    "Panama",
    "Cuba",
    "Jamaica",
    "Haiti",
    "Dominican Republic",
    "Bahamas",
    "Trinidad and Tobago",
    "Barbados",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Antigua and Barbuda",
    "Dominica",
    "Grenada",
    "Saint Kitts and Nevis",
    "Honduras",
    "Nicaragua",
    "Costa Rica",
    "Panama",
  ],
  "South America": [
    "Argentina",
    "Bolivia",
    "Brazil",
    "Chile",
    "Colombia",
    "Ecuador",
    "Guyana",
    "Paraguay",
    "Peru",
    "Suriname",
    "Uruguay",
    "Venezuela",
  ],
  "South East Asia": [
    "Brunei",
    "Cambodia",
    "East Timor",
    "Indonesia",
    "Laos",
    "Malaysia",
    "Myanmar",
    "Philippines",
    "Singapore",
    "Thailand",
    "Vietnam",
  ],
  "Rest of Asia": [
    "Afghanistan",
    "Armenia",
    "Azerbaijan",
    "Bahrain",
    "Bangladesh",
    "Bhutan",
    "China",
    "Cyprus",
    "Georgia",
    "India",
    "Iran",
    "Iraq",
    "Israel",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kuwait",
    "Kyrgyzstan",
    "Lebanon",
    "Maldives",
    "Mongolia",
    "Nepal",
    "North Korea",
    "Oman",
    "Pakistan",
    "Qatar",
    "Saudi Arabia",
    "South Korea",
    "Sri Lanka",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Turkey",
    "Turkmenistan",
    "United Arab Emirates",
    "Uzbekistan",
    "Yemen",
  ],
};
