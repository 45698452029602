import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";
import { shortlistVendors } from "../actions/projects";
const FETCH_BIDS_CONSUMER = "FETCH_BIDS_CONSUMER";

const initialState = {
  projects: [],
  currentProject: { loading: false, error: "" },
  error: null,
  loading: false,
  loadNewBids: false,
  vendors: [],
  recommendedVendors: [],
  suggested_vendors: [],
  vendorsLoading: false,
  vendorsError: false,
  shortlistSuccess: false,
  closureSuccess: false,
};

const getProjectListStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getProjectListSuccess = (state, action) => {
  return updateObject(state, {
    projects: action.projects,
    error: null,
    loading: false,
    loadNewBids: false,
  });
};

const getProjectListFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    loadNewBids: false,
  });
};

const getProjectDetailStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
    currentProject: { loading: true },
  });
};

const getProjectDetailSuccess = (state, action) => {
  return updateObject(state, {
    currentProject: { ...action.project, loading: false, error: "" },
    error: null,
    loading: false,
    loadNewBids: false,
  });
};

const getProjectDetailFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    loadNewBids: false,
    currentProject: { loading: false, error: action.error },
  });
};

const clearProjectDetail = (state, action) => {
  return updateObject(state, {
    currentProject: {},
  });
};

const createProjectStart = (state, action) => {
  return updateObject(state, {
    error: null,
  });
};

const createProjectSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
  });
};

const createProjectFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
  });
};

const emitNewBid = (state, action) => {
  return updateObject(state, {
    loadNewBids: true,
  });
};

const getVendorDetailsLoading = (state, action) => {
  return updateObject(state, {
    vendorsLoading: true,
  });
};

const getVendorDetailsSuccess = (state, action) => {
  return updateObject(state, {
    vendors: action.vendors,
    suggested_vendors: action.suggested_vendors,
    vendorsLoading: false,
  });
};

const getVendorDetailsFail = (state, action) => {
  return updateObject(state, {
    vendorsLoading: false,
    vendorsError: true,
  });
};
const getSiteDetailsLoading = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const getSiteDetailsSuccess = (state, action) => {
  return updateObject(state, {
    siteDetails: action.payload,
    loading: false,
  });
};

const getSiteDetailsFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: true,
  });
};

const getAllVendorsLoading = (state, action) => {
  return updateObject(state, {
    vendorsLoading: true,
  });
};

const getAllVendorsSuccess = (state, action) => {
  return updateObject(state, {
    myVendors: action.myVendors,
    recommendedVendors: action.recommended_vendors,
    vendorsLoading: false,
  });
};

const getAllVendorsFail = (state, action) => {
  return updateObject(state, {
    vendorsLoading: false,
    vendorsError: true,
  });
};

const shortlistVendorsSuccess = (state, action) => {
  return updateObject(state, {
    shortlistSuccess: action.successs,
  });
};

const finalizeMeetingSuccess = (state, action) => {
  return updateObject(state, {
    closureSuccess: action.success,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PROJECT_LIST_START:
      return getProjectListStart(state, action);
    case actionTypes.GET_PROJECT_LIST_SUCCESS:
      return getProjectListSuccess(state, action);
    case actionTypes.GET_PROJECT_LIST_FAIL:
      return getProjectListFail(state, action);
    case actionTypes.GET_PROJECT_DETAIL_START:
      return getProjectDetailStart(state, action);
    case actionTypes.GET_PROJECT_DETAIL_SUCCESS:
      return getProjectDetailSuccess(state, action);
    case actionTypes.GET_PROJECT_DETAIL_FAIL:
      return getProjectDetailFail(state, action);
    case actionTypes.CLEAR_PROJECT_DETAIL:
      return clearProjectDetail(state, action);
    case actionTypes.CREATE_PROJECT_START:
      return createProjectStart(state, action);
    case actionTypes.CREATE_PROJECT_SUCCESS:
      return createProjectSuccess(state, action);
    case actionTypes.CREATE_PROJECT_FAIL:
      return createProjectFail(state, action);
    case FETCH_BIDS_CONSUMER:
      return emitNewBid(state, action);
    case actionTypes.GET_VENDOR_DETAILS_LOADING:
      return getVendorDetailsLoading(state, action);
    case actionTypes.GET_VENDOR_DETAILS_SUCCESS:
      return getVendorDetailsSuccess(state, action);
    case actionTypes.GET_VENDOR_DETAILS_FAIL:
      return getVendorDetailsFail(state, action);
    case actionTypes.SHORTLIST_VENDORS:
      return shortlistVendorsSuccess(state, action);
    case actionTypes.FINALIZE_MEETING:
      return finalizeMeetingSuccess(state, action);
    case actionTypes.GET_ALL_VENDORS_LOADING:
      return getAllVendorsLoading(state, action);
    case actionTypes.GET_ALL_VENDORS_SUCCESS:
      return getAllVendorsSuccess(state, action);
    case actionTypes.GET_ALL_VENDORS_FAIL:
      return getAllVendorsFail(state, action);
    case actionTypes.GET_SITE_DETAILS_LOADING:
      return getSiteDetailsLoading(state, action);
    case actionTypes.GET_SITE_DETAILS_SUCCESS:
      return getSiteDetailsSuccess(state, action);
    case actionTypes.GET_SITE_DETAILS_FAIL:
      return getSiteDetailsFail(state, action);
    default:
      return state;
  }
};

export default reducer;
