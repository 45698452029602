import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import "animate.css/animate.min.css";
import "semantic-ui-css/semantic.min.css";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import authReducer from "./store/reducers/auth";
import projectReducer from "./store/reducers/project";
import homeReducer from "./store/reducers/home";
import vendorReducer from "./store/reducers/vendor";
import jobsReducers from "./store/reducers/jobs-reducers";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import { BreakpointProvider } from "react-socks";
import "./index.css";
import './App.less';
import participantsReducers from "./store/reducers/participants-reducers";
import dataDashboardReducers from "./store/reducers/data-dashboard-reducers";
import nonAuctionReducers from "./store/reducers/non-auction-quote-reducers";
import ssoReducers from "./store/reducers/sso-reducers";
import safearthDashboardReducers from "./store/reducers/safearth-dashboard-reducers";
import getProjectReducers from './store/reducers/get-project-reducers';
import PMTReRoute from "./routes/platform-integration-routes/pmt-reroute";
import EcomReRoute from "./routes/platform-integration-routes/ecom-reroute";
import financeReducers from './store/reducers/finance-reducers';

// const composeEnhances = compose;
// disableReactDevTools();

const composeEnhances = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

require('dotenv').config('./env')

const TermsAndConditions = lazy(() => import("./Components/LandingPage/TermsAndConditions"));
const Privacy = lazy(() => import("./Components/LandingPage/Privacy"));

const MySnapScroll = lazy(() =>
  import("./Components/LandingPage/MySnapScroll")
);
const LandingPage = lazy(() => import("./Components/LandingPage/LandingPage"));
const ContactUs = lazy(() => import("./Components/LandingPage/ContactUs"));
const LogIn = lazy(() => import("./Components/LandingPage/Login/Login"));
const Signup = lazy(() => import("./Components/LandingPage/Signup/Signup"));
const SplashScreen = lazy(() => import("./Components/SplashScreen/SplashScreen"));
const VendorSplashScreen = lazy(() => import("./Components/SplashScreen/vendor/VendorSplashScreen"));
const BecomeAVendor = lazy(() =>
  import("./Components/LandingPage/BecomeAVendor")
);
const Projects = lazy(() => import("./Components/LandingPage/Projects"));
const Calculator = lazy(() => import("./Components/LandingPage/Calculator"));
const Team = lazy(() => import("./Components/LandingPage/Team"));
const FAQ = lazy(() => import("./Components/LandingPage/FAQ"));
const VendorDashboard = lazy(() =>
  import("./Components/VendorPlatform/VendorDashboard")
);
const VendorSideAuctionPage = lazy(() =>
  import("./Components/VendorPlatform/VendorSideAuctionPage")
);
const NonAuctionProjectPage = lazy(() =>
  import("./Components/VendorPlatform/NonAuctionProjectPage")
);
const VendorSearchPage = lazy(() =>
  import("./Components/VendorPlatform/VendorSearchPage")
);
const VendorSideBoqPage = lazy(() =>
  import("./Components/VendorPlatform/VendorSideBoqPage")
);
const VendorProfilePage = lazy(() =>
  import("./Components/VendorPlatform/VendorProfilePage")
);
const CustomerSideSelectProject = lazy(() =>
  import("./Components/CustomerSide/CustomerDashboard")
);
const CustomerFaqPage = lazy(() =>
  import("./Components/CustomerSide/CustomerSideFaqPage")
);
const CustomerSideProjectDashboard = lazy(() =>
  import("./Components/CustomerSide/CustomerSideProjectDashboard")
);
const CustomerSideVendorProfilePage = lazy(() =>
  import("./Components/CustomerSide/CustomerSideVendorProfilePage")
);
const AdminProjects = lazy(() => import("./Components/Admin/AdminProjects"));
const AdminProjectDashboard = lazy(() =>
  import("./Components/Admin/AdminProjectDashboard")
);
const RegistrationPage = lazy(() =>
  import("./Components/LandingPage/RegistrationPage")
);
const ProfilePageInEditMode = lazy(() =>
  import("./Components/VendorPlatform/ProfilePageInEditMode")
);
const VendorOnboarding = lazy(() =>
  import("./routes/vendor-onboarding-route/vendor-onboarding.route")
);
const ProfileComplete = lazy(() =>
import("./Components/VendorOnboarding/ProfileComplete")
);
const CustomerOnboarding = lazy(() =>
import("./routes/customer-onboarding-route/customer-onboarding.route")
);
const ResetPassword = lazy(() => import("./routes/reset-password-route/reset-password.route"));
const HostAuction = lazy(() => import("./routes/host-auction-route/host-auction.route"));
const HostedAuctionPage = lazy(() =>
  import("./Components/VendorPlatform/HostedAuctionPage")
);
const VendorSideVendorProfilePage = lazy(() =>
  import("./Components/VendorPlatform/VendorSideVendorProfilePage")
);
const VendorAdminSideVendorProfilePage = lazy(() =>
  import("./Components/VendorPlatform/VendorAdmin/VendorAdminSideVendorProfilePage")
);
const VendorAdminSideProfilePageInEditMode = lazy(() =>
  import("./Components/VendorPlatform/VendorAdmin/VendorAdminSideProfilePageInEditMode")
);
const JobDescriptionRoute = lazy(() =>
  import("./routes/job-description-route/job-description-route")
);
const DataDashboard = lazy(() =>
  import("./routes/data-dashboard-route/data-dashboard-login.route")
);
const ListOfCompanies = lazy(() =>
  import("./routes/data-dashboard-route/list-of-companies.route")
);
const NewSignUps = lazy(() =>
  import("./routes/data-dashboard-route/new-signups.route")
);
const DataDashboardVendorProfile = lazy(() =>
  import("./Components/DataDashboard/VendorProfile")
);
const FinancingPage = lazy(() =>
  import("./Components/LandingPage/FinancingPage")
);
const RequestFinancingMobile = lazy(() =>
  import("./Components/LandingPageMobileVersion/Finance/RequestFinancingMobile")
);
const FinanceSignUp = lazy(() =>
  import("./Components/LandingPageMobileVersion/Finance/FinanceSignupMobile")
);
const SafEarthDashboard = lazy(() =>
  import("./routes/safearth-dashboard-route/safearth-dashboard.route")
);
const DownloadMobileApp = lazy(() => import ("./Components/LandingPage/Login/DownloadMobileApp"));

// const rootReducer = combineReducers({
//   auth: authReducer,
//   project: projectReducer,
//   home: homeReducer,
//   vendor: vendorReducer,
// });

const rootReducer = combineReducers({
  auth: authReducer,
  project: projectReducer,
  home: homeReducer,
  vendor: vendorReducer,
  jobsReducers,
  participantsReducers,
  dataDashboardReducers,
  nonAuctionReducers,
  ssoReducers,
  safearthDashboardReducers,
  getProjectReducers,
  financeReducers
});

const store = createStore(rootReducer, composeEnhances(applyMiddleware(thunk)));
// const store = createStore(reducers, composeEnhances(applyMiddleware(thunk)));

ReactDOM.render(
    <BreakpointProvider>
      <Provider store={store}>
        <Router>
          <Suspense fallback={<div></div>}>
            <Switch>
              <Route
                path="/terms-and-conditions"
                component={TermsAndConditions}
              />
              <Route path="/privacy-policy" component={Privacy} />

              {/* "---------------Auction Pages----------------" */}
              <Route exact path="/" component={LandingPage} />
              <Route path="/landing/customer" render={() => <MySnapScroll userType="customer" />} />
              <Route path="/landing/vendor" render={() => <MySnapScroll userType="vendor" />} />
              <Route path="/contactUs" component={ContactUs} />
              {/* <Route path="/logIn" component={LogIn} /> */}
              <Redirect
                exact
                from="/logIn"
                to="/logIn/vendor"
              />
              {/* <Redirect
                exact
                from="/logIn/marketplace"
                to="/logIn/marketplace/vendor"
              /> */}
              <Redirect
                exact
                from="/logIn/vendor"
                to="/logIn/marketplace"
              />
              <Redirect
                exact
                from="/logIn/vendor/next"
                to="/logIn/marketplace/vendor/next"
              />
              <Redirect
                exact
                from="/logIn/customer"
                to="/logIn/marketplace"
              />
              <Redirect
                exact
                from="/logIn/customer/next"
                to="/logIn/marketplace/customer/next"
              />
              <Route exact path="/logIn/marketplace" render={() => <LogIn />} />
              {/* <Route exact path="/logIn/vendor" render={() => <LogIn userType="vendor" />} />
              <Route exact path="/logIn/customer" render={() => <LogIn userType="customer" />} /> */}
              <Route exact path="/logIn/marketplace/customer/next" render={() => <SplashScreen userType="customer" /> } />
              <Route exact path="/logIn/marketplace/vendor/next" render={() => <SplashScreen userType="vendor" /> } />
              {/* <Route exact path="/logIn/customer/next" render={() => <SplashScreen userType="customer" /> } />
              <Route exact path="/logIn/vendor/next" render={() => <SplashScreen userType="vendor" /> } /> */}
              {/* <Route path="/signUp" component={Signup} /> */}
              <Redirect
                exact
                from="/signUp"
                to="/signUp/vendor"
              />
              <Route exact path="/signUp/customer" render={() => <Signup userType="customer" /> } />
              <Route exact path="/signUp/vendor" render={() => <Signup userType="vendor" /> } />
              <Route exact path="/signUp/customer/next" render={() => <SplashScreen userType="customer" /> } />
              <Route exact path="/signUp/vendor/next" render={() => <SplashScreen userType="vendor" /> } />
              <Route path="/becomeAVendor" component={BecomeAVendor} />
              <Route path="/projects" component={Projects} />
              <Route path="/calculator" component={Calculator} />
              <Route path="/team" component={Team} />
              <Route path="/faq" component={FAQ} />
              <Route path="/vendorDashboard" component={VendorDashboard} />
              <Route path="/vendorAuction" component={VendorSideAuctionPage} />
              <Route
                path="/vendorNonAuction"
                component={NonAuctionProjectPage}
              />
              <Route path="/vendorSearch" component={VendorSearchPage} />
              <Route path="/vendorBoq" component={VendorSideBoqPage} />
              <Route path="/vendorProfile" component={VendorProfilePage} />
              <Route
                path="/userProjects"
                component={CustomerSideSelectProject}
              />
              <Route
                path="/userProjectDashboard"
                component={CustomerOnboarding}
              />
              <Route path="/customerFaq" component={CustomerFaqPage} />
              <Route
                path="/customerSideVendorProfilePage/:vendorId"
                component={CustomerSideVendorProfilePage}
              />
              <Route path="/adminProjects" component={AdminProjects} />
              <Route
                path="/adminProjectDashboard"
                component={AdminProjectDashboard}
              />
              <Route path="/register" component={RegistrationPage} />
              <Route path="/edit-profile" component={ProfilePageInEditMode} />
              <Route path="/onboarding/vendor" component={VendorOnboarding} />
              <Route
                exact
                path="/onboarding/vendor/success"
                component={ProfileComplete}
              />
              <Route exact path="/reset-password" component={ResetPassword} />
              <Route path="/host-auction" component={HostAuction} />
              <Route path="/hostedAuction" component={HostedAuctionPage} />
              <Route
                path="/vendorSideVendorProfilePage/:vendorId"
                component={VendorSideVendorProfilePage}
              />
              <Route
                path="/vendorAdmin/vendorProfilePage"
                component={VendorAdminSideVendorProfilePage}
              />
              <Route path="/vendorAdmin/edit-profile" component={VendorAdminSideProfilePageInEditMode} />
              <Route path="/job/:jobId" component={JobDescriptionRoute} />
              <Route path="/job" render={() => <JobDescriptionRoute emptyCase />} />
              <Route
                // path="/data-dashboard-362797ff-b96a-4d0a-ba50-8ea70411d726"
                path="/data-dashboard"
                component={DataDashboard}
              />
              <Route
                path="/companies"
                component={ListOfCompanies}
              />
              <Route
                path="/new-signups"
                component={NewSignUps}
              />
              <Route
                path="/vendor-profile/:vendorId"
                component={DataDashboardVendorProfile}
              />
              <Route
                exact
                path="/financing"
                component={FinancingPage}
              />
              <Route
                path="/request-financing"
                component={RequestFinancingMobile}
              />
              <Route exact path="/financing/signUp" component={FinanceSignUp} />
              <Route path="/dashboard" component={SafEarthDashboard} />
              <Route
                path="/pmt"
                component={PMTReRoute}
              />
              <Route
                path="/ecom"
                component={EcomReRoute}
              />
              <Route exact path="/download-mobile-app" component={DownloadMobileApp} />
            </Switch>
          </Suspense>
        </Router>
      </Provider>
    </BreakpointProvider>,
    document.getElementById("root")
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
