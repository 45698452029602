import { message } from "antd";
import axios from "axios";
import * as actionTypes from "./actionTypes";
import { ssoAuthenticator } from "./sso";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (user) => {
  localStorage.setItem("is_authenticated", true);
  message.success(`You are successfully logged in!`, 1);
  return {
    type: actionTypes.AUTH_SUCCESS,
    user,
  };
};

export const authFail = (error) => {
  localStorage.removeItem("is_authenticated");
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const logoutSuccess = () => {
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const resetPasswordLinkSuccess = (detail) => {
  return {
    type: actionTypes.RESET_PASSWORD_LINK,
    detail,
  };
};

export const clearResetResponse = () => {
  return {
    type: actionTypes.CLEAR_RESET_RESPONSE,
  };
};

// export const resetPasswordLinkFail = () => {
//   return {
//     type: actionTypes.RESET_PASSWORD_LINK
//   }
// }

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
      localStorage.removeItem("user_type");
      localStorage.removeItem("is_authenticated");
      dispatch(logout());
    }, expirationTime * 1000 * 12);
  };
};

const pendingRegistration = (is_pending, vendorId) => {
  if (is_pending) {
    message.info("Registration pending!");
  }
  return {
    type: actionTypes.PENDING_REGISTRATION,
    pendingRegistration: is_pending,
    vendorId,
  };
};

export const authLogin = (username, password, props, isMobileLogin, isNewUser) => {
  return async (dispatch) => {
    dispatch(authStart());
    let form = new FormData();
    form.append("email", username);
    form.append("password", password);

    axios.defaults.headers = {
      "Content-Type": "application/json",
    };
    // https://safearth-api.in/rest-auth/login/
    // http://15.206.127.45/rest-auth/login/

    await axios
      .post(`${process.env.REACT_APP_BASE_URL}pending_registration/`, form)
      .then((res) => {
        if (res.data.vendor_id) {
          localStorage.setItem("vendorId", res.data.vendor_id);
          dispatch(pendingRegistration(true, res.data.vendor_id));
          return true;
        } else {
          axios
            .post(`https://safearth-api.in/rest-auth/login/`, {
              username: username,
              password: password,
            })
            .then((res) => {
              const user = {
                token: res.data.key,
                username,
                userId: res.data.user,
                is_consumer: res.data.user_type.is_consumer,
                is_vendor: res.data.user_type.is_vendor,
                is_admin: res.data.user_type.is_admin,
                name: res.data.user_type.name,
                company_name: res.data.user_type.company_name,
                expirationDate: new Date(new Date().getTime() + 3600 * 1000),
                first_login: res.data.user_type.first_login,
              };

              localStorage.setItem("user", JSON.stringify(user));
              localStorage.setItem("is_authenticated", "true");
              localStorage.setItem("logged_out", "false");
              localStorage.removeItem("vendor_id");
              if (res.data?.user_type?.phone_number) {
                localStorage.setItem(
                  "mobile_number",
                  res.data?.user_type.phone_number
                );
              }
              if (res.data.user_type.is_admin === true) {
                localStorage.setItem("user_type", "is_admin");
              } else if (res.data.user_type.is_consumer === true) {
                localStorage.setItem("user_type", "is_consumer");
                if (isMobileLogin && props) {
                  if (isNewUser) {
                    props.history.push("/logIn/customer/next");
                  } else {
                    props.history.push("/download-mobile-app");
                  }
                }
              } else {
                localStorage.setItem("user_type", "is_vendor");
                if (isMobileLogin && props) {
                  if (isNewUser) {
                    props.history.push("/logIn/vendor/next");
                  } else {
                    props.history.push("/download-mobile-app");
                  }
                }
              }
              dispatch(pendingRegistration(false, null));
              dispatch(authSuccess(user));
              dispatch(checkAuthTimeout(3600));
              ssoAuthenticator(user, res.data.key, dispatch, password);
            })
            .catch((err) => {
              message.error("Invalid Username or password!");
              dispatch(authFail(err));
            });
        }
      });
  };
};

export const loginUser = (username, password) => (dispatch) => {
  dispatch({ type: actionTypes.LOGIN_USER_LOADING, payload: null });
  axios
    .post("https://safearth-api.in/rest-auth/login/", {
      username: username,
      password: password,
    })
    .then((res) => {
      dispatch({
        type: actionTypes.LOGIN_USER_SUCCESS,
        payload: res.data,
      });
      const user = {
        token: res.data.key,
        username,
        userId: res.data.user,
        is_consumer: res.data.user_type.is_consumer,
        is_vendor: res.data.user_type.is_vendor,
        is_admin: res.data.user_type.is_admin,
        expirationDate: new Date(new Date().getTime() + 3600 * 1000),
        first_login: res.data.user_type.first_login,
      };

      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("is_authenticated", "true");
      localStorage.setItem("logged_out", "false");
      localStorage.removeItem("vendor_id");
      if (res.data.user_type.is_admin === true) {
        localStorage.setItem("user_type", "is_admin");
      } else if (res.data.user_type.is_consumer === true) {
        localStorage.setItem("user_type", "is_consumer");
      } else {
        localStorage.setItem("user_type", "is_vendor");
      }
      dispatch({
        type: actionTypes.AUTH_SUCCESS,
        user,
      });
      dispatch(checkAuthTimeout(3600));
    })
    .catch((e) => {
      message.error(e?.response?.data?.non_field_errors?.[0]);
      dispatch({
        type: actionTypes.LOGIN_USER_FAILURE,
        payload: { message: e?.response?.data?.non_field_errors?.[0] },
      });
    });
};

export const signup =
  (name, email, password, company, consumer, vendor, phone, otp) =>
  async (dispatch) => {
    dispatch({ type: actionTypes.SIGN_UP_START, payload: null });

    await message.loading("Verifying OTP...");

    try {
      const result = await axios.get(
        `${process.env.REACT_APP_BASE_URL}signup/`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            name: name,
            email: email,
            password: password,
            company: company,
            consumer: consumer,
            vendor: vendor,
            phone: phone,
            otp,
          },
        }
      );
      if (result.data.status === "FAIL") {
        message.error(result.data.error);
        return result.data;
      }

      if (result.status === 200) {
        localStorage.setItem("vendorId", result.data.vendor_id);
        dispatch({ type: actionTypes.SIGN_UP_SUCCESS, payload: result.data });
        message.success(`OTP sent to ${email}`);
        localStorage.setItem("companyName", company);
        return result;
      }
    } catch (err) {
      message.error(err.message);
      return;
    }
  };

export const submitVendorDetails = (vendorInfo) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.SIGN_UP_START, payload: null });
    axios
      .get(`${process.env.REACT_APP_BASE_URL}signup/`, {
        params: vendorInfo,
      })
      .then((res) => {
        localStorage.setItem("vendorId", res.data.vendor_id);
        dispatch({ type: actionTypes.SIGN_UP_SUCCESS, payload: res.data });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.SIGN_UP_FAILURE,
          payload: { message: err.message },
        });
      });
  };
};

export const logout = (token) => {
  // localStorage.removeItem("is_authenticated");
  // localStorage.removeItem("user_type");
  // localStorage.removeItem("user");
  // localStorage.removeItem("pmtToken");
  // localStorage.removeItem("ecomToken");
  localStorage.clear();
  localStorage.setItem("logged_out", "true");
  // clearItems();
  return (dispatch) => {
    axios
      .post(`https://safearth-api.in/rest-auth/logout/`)
      .then((res) => {
        dispatch(logoutSuccess());
      })
      .catch((err) => {});
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user === undefined || user == null) {
      return;
    }
    dispatch(authSuccess(user));
    dispatch(checkAuthTimeout());
  };
};

export const resetPasswordLink = (email) => async (dispatch) => {
  try {
    const result = await axios.post(
      "https://safearth-api.in/rest-auth/password/reset/",
      {
        email: email,
      }
    );
    const detail = result.data.detail;
    message.success(detail);
    dispatch(resetPasswordLinkSuccess(detail));
    return result;
  } catch (e) {
    message.error("Error occurred while resetting password!");
    dispatch(resetPasswordLinkSuccess(e));
  }
};

export const getEmailConfirmation = (email) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_OTP_LOADING, payload: null });

  await message.loading("Sending OTP...");

  try {
    const result = await axios.get(
      `${process.env.REACT_APP_BASE_URL}send_email_confirmation/`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          email: email,
        },
      }
    );
    if (result.data.status === "FAIL") {
      message.error(result.data.response);
      return;
    }

    if (result.status === 200) {
      dispatch({ type: actionTypes.GET_OTP_SUCCESS, payload: result.data });
      message.success(`OTP sent to ${email}`);
      return result;
    }
  } catch (err) {
    message.error(err.message);
    return;
  }
};

export const resetFirstLogin = () => (dispatch) => {
  dispatch({ type: actionTypes.RESET_FIRST_LOGIN });
};
