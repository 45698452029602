import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";
const FETCH_BIDS = "FETCH_BIDS";
const FETCH_MY_BIDS = "FETCH_MY_BIDS";

const initialState = {
  ongoingProjects: [],
  activeProjects: [],
  closedProjects: [],
  nonAuctionProjects: [],
  hostedProjects: [],
  currentProject: { date: "2019-05-31T06:00:00", bid_list: [] },
  searchProjects: [],
  error: null,
  loading: true,
  ok: false,
  loadNewBids: false,
  recentBid: false,
  specifications: [],
  specification: {},
  stats: {},
  vendorStats: {},
  vendorEmail: "",
  vendorCompany: "",
  vendorLogo: "",
  profile: {},
  customerSideVendorProfile: {},
  pendingVendorProfile: {},
  subUserProjects: [],
  is_sub_user: false,
  sub_users: [],
  subUserCreated: false,
  subUserAdded: false,
  subUserFail: false,
  bidFail: false,
  bidSuccess: false,
  bidError: "",
  specificationError: null,
  specificationCreated: false,
  specificationUpdated: false,
  specificationDeleted: false,
  secondaryEmails: [],
  incompleteProfile: false,
  showWalkthrough: false,
  vendorAdminLoading: false,
  vendorAdminSuccess: false,
};

const getProjectListStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getProjectListSuccess = (state, action) => {
  return updateObject(state, {
    ongoingProjects: action.projects.ongoing,
    activeProjects: action.projects.active,
    closedProjects: action.projects.closed,
    nonAuctionProjects: action.projects.non_auction_projects,
    hostedProjects: action.projects.hosted,
    stats: action.projects.stats,
    vendorStats: action.projects.vendor_stats,
    vendorEmail: action.projects.vendor_email,
    vendorCompany: action.projects.vendor_company,
    vendorLogo: action.projects.vendor_logo,
    incompleteProfile: action.projects.incomplete_profile,
    showWalkthrough: action.projects.show_walkthrough,
    error: null,
    loading: false,
  });
};

const getProjectListFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
  });
};

const getVendorStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getVendorSuccess = (state, action) => {
  return updateObject(state, {
    profile: action.profile,
    error: null,
    loading: false,
    ok: true,
  });
};

const getVendorFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
  });
};

const getPendingVendorStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
    ok: false,
  });
};

const getPendingVendorSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    ok: true,
    pendingVendorProfile: action.payload,
    error: null,
  });
};

const getPendingVendorFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    ok: false,
    error: action.payload.message,
  });
};

const getCustomerSideVendorProfileStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getCustomerSideVendorProfileSuccess = (state, action) => {
  return updateObject(state, {
    customerSideVendorProfile: action.profile,
    error: null,
    loading: false,
  });
};

const getCustomerSideVendorProfileFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
  });
};

const getProjectDetailStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    bidError: "",
    bidFail: false,
    bidSuccess: false,
    loadNewBids: false,
  });
};

const getProjectDetailSuccess = (state, action) => {
  return updateObject(state, {
    currentProject: action.project,
    error: null,
    loading: false,
    loadNewBids: false,
    recentBid: false,
  });
};

const getProjectDetailFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loadNewBids: false,
    bidError: "",
    bidFail: false,
    bidSuccess: false,
    recentBid: false,
  });
};

const getSpecificationStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getSpecificationSuccess = (state, action) => {
  return updateObject(state, {
    specifications: action.specifications,
    error: null,
    loading: false,
    loadNewBids: false,
    recentBid: false,
  });
};

const getSpecificationFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loadNewBids: false,
    recentBid: false,
  });
};

const getSpecificationDetailStart = (state, action) => {
  return updateObject(state, {
    error: null,
  });
};

const getSpecificationDetailSuccess = (state, action) => {
  return updateObject(state, {
    specification: action.specification,
    error: null,
    loading: false,
    loadNewBids: false,
    recentBid: false,
  });
};

const getSpecificationDetailFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loadNewBids: false,
    recentBid: false,
  });
};

const createSpecificationStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    specificationError: null,
  });
};

const createSpecificationSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    specificationError: null,
    specificationCreated: true,
  });
};

const createSpecificationFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    specificationError: action.error,
  });
};

const updateSpecificationStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    specificationError: null,
  });
};

const updateSpecificationSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    specificationError: null,
    specificationUpdated: true,
  });
};

const updateSpecificationFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    specificationError: action.error,
  });
};

const deleteSpecificationStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    specificationError: null,
  });
};

const deleteSpecificationSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    specificationError: null,
    specificationDeleted: true,
  });
};

const deleteSpecificationFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    specificationError: action.error,
  });
};

const getSearchStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getSearchSuccess = (state, action) => {
  return updateObject(state, {
    searchProjects: action.projects,
    error: null,
    loading: false,
    loadNewBids: false,
    recentBid: false,
  });
};

const getSearchFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loadNewBids: false,
    recentBid: false,
  });
};

const emitNewBid = (state, action) => {
  return updateObject(state, {
    loadNewBids: true,
  });
};

const emitNewMyBid = (state, action) => {
  return updateObject(state, {
    recentBid: true,
  });
};

const getSecondaryEmailsStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const getSecondaryEmailsSuccess = (state, action) => {
  return updateObject(state, {
    secondaryEmails: action.secondaryEmails,
    loading: false,
    error: null,
  });
};

const getSecondaryEmailsFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const handleGetSubUsersSuccess = (state, action) => {
  return updateObject(state, {
    subUserProjects: action.data.projects,
    sub_users: action.data.sub_users,
    is_sub_user: action.data.is_sub_user,
  });
};

const handleCreateSubUserSuccess = (state, action) => {
  return updateObject(state, {
    subUserCreated: true,
  });
};

const handleCloseSnackBar = (state, action) => {
  return updateObject(state, {
    subUserCreated: false,
    subUserAdded: false,
    subUserFail: false,
  });
};

const handleSubUserAdded = (state, action) => {
  return updateObject(state, {
    subUserAdded: true,
  });
};

const handleCreateSubUserFail = (state, action) => {
  return updateObject(state, {
    subUserFail: true,
  });
};

const handleBidFail = (state, action) => {
  return updateObject(state, {
    bidFail: true,
    bidSuccess: false,
    bidError: action.bidError,
  });
};

const handleBidSuccess = (state, action) => {
  return updateObject(state, {
    bidSuccess: true,
    bidFail: false,
  });
};

const handleCloseSnackBarBid = (state, action) => {
  return updateObject(state, {
    bidSuccess: false,
    bidFail: false,
    bidError: null,
  });
};

const getVendorAdminTokenLoading = (state, action) => {
  return updateObject(state, {
    vendorAdminLoading: true,
    vendorAdminSuccess: false,
    error: null,
  });
};

const getVendorAdminTokenSuccess = (state, action) => {
  return updateObject(state, {
    vendorAdminLoading: false,
    vendorAdminSuccess: true,
    error: null,
  });
};

const getVendorAdminTokenFailure = (state, action) => {
  return updateObject(state, {
    vendorAdminLoading: false,
    vendorAdminSuccess: false,
    error: action.error,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_VENDOR_PROJECT_LIST_START:
      return getProjectListStart(state, action);
    case actionTypes.GET_VENDOR_PROJECT_LIST_SUCCESS:
      return getProjectListSuccess(state, action);
    case actionTypes.GET_VENDOR_PROJECT_LIST_FAIL:
      return getProjectListFail(state, action);
    case actionTypes.GET_VENDOR_PROJECT_DETAIL_START:
      return getProjectDetailStart(state, action);
    case actionTypes.GET_VENDOR_PROJECT_DETAIL_SUCCESS:
      return getProjectDetailSuccess(state, action);
    case actionTypes.GET_VENDOR_PROJECT_DETAIL_FAIL:
      return getProjectDetailFail(state, action);
    case FETCH_BIDS:
      return emitNewBid(state, action);
    case FETCH_MY_BIDS:
      return emitNewMyBid(state, action);
    case actionTypes.GET_SPECIFICATION_FAIL:
      return getSpecificationFail(state, action);
    case actionTypes.GET_SPECIFICATION_SUCCESS:
      return getSpecificationSuccess(state, action);
    case actionTypes.GET_SPECIFICATION_START:
      return getSpecificationStart(state, action);
    case actionTypes.GET_SPECIFICATION_DETAIL_FAIL:
      return getSpecificationDetailFail(state, action);
    case actionTypes.GET_SPECIFICATION_DETAIL_START:
      return getSpecificationDetailStart(state, action);
    case actionTypes.GET_SPECIFICATION_DETAIL_SUCCESS:
      return getSpecificationDetailSuccess(state, action);

    case actionTypes.CREATE_SPECIFICATION_START:
      return createSpecificationStart(state, action);
    case actionTypes.CREATE_SPECIFICATION_SUCCESS:
      return createSpecificationSuccess(state, action);
    case actionTypes.CREATE_SPECIFICATION_FAIL:
      return createSpecificationFail(state, action);

    case actionTypes.UPDATE_SPECIFICATION_START:
      return updateSpecificationStart(state, action);
    case actionTypes.UPDATE_SPECIFICATION_SUCCESS:
      return updateSpecificationSuccess(state, action);
    case actionTypes.UPDATE_SPECIFICATION_FAIL:
      return updateSpecificationFail(state, action);

    case actionTypes.DELETE_SPECIFICATION_START:
      return deleteSpecificationStart(state, action);
    case actionTypes.DELETE_SPECIFICATION_SUCCESS:
      return deleteSpecificationSuccess(state, action);
    case actionTypes.DELETE_SPECIFICATION_FAIL:
      return deleteSpecificationFail(state, action);

    case actionTypes.GET_SEARCH_START:
      return getSearchStart(state, action);
    case actionTypes.GET_SEARCH_FAIL:
      return getSearchFail(state, action);
    case actionTypes.GET_SEARCH_SUCCESS:
      return getSearchSuccess(state, action);
    case actionTypes.GET_VENDOR_START:
      return getVendorStart(state, action);
    case actionTypes.GET_VENDOR_SUCCESS:
      return getVendorSuccess(state, action);
    case actionTypes.GET_VENDOR_FAIL:
      return getVendorFail(state, action);

    case actionTypes.GET_CUSTOMER_SIDE_VENDOR_PROFILE_START:
      return getCustomerSideVendorProfileStart(state, action);
    case actionTypes.GET_CUSTOMER_SIDE_VENDOR_PROFILE_SUCCESS:
      return getCustomerSideVendorProfileSuccess(state, action);
    case actionTypes.GET_CUSTOMER_SIDE_VENDOR_PROFILE_FAIL:
      return getCustomerSideVendorProfileFail(state, action);

    case actionTypes.GET_SECONDARY_EMAILS_START:
      return getSecondaryEmailsStart(state, action);
    case actionTypes.GET_SECONDARY_EMAILS_SUCCESS:
      return getSecondaryEmailsSuccess(state, action);
    case actionTypes.GET_SECONDARY_EMAILS_FAIL:
      return getSecondaryEmailsFail(state, action);

    case actionTypes.GET_SUB_USERS:
      return handleGetSubUsersSuccess(state, action);
    case actionTypes.CREATE_SUB_USER:
      return handleCreateSubUserSuccess(state, action);
    case actionTypes.CLOSE_SNACK_BAR:
      return handleCloseSnackBar(state, action);
    case actionTypes.ADD_SUB_USER:
      return handleSubUserAdded(state, action);
    case actionTypes.CREATE_SUB_USER_FAIL:
      return handleCreateSubUserFail(state, action);
    case actionTypes.BID_FAIL:
      return handleBidFail(state, action);
    case actionTypes.BID_SUCCESS:
      return handleBidSuccess(state, action);
    case actionTypes.CLOSE_SNACKBAR_BID:
      return handleCloseSnackBarBid(state, action);
    case actionTypes.GET_PENDING_VENDOR_START:
      return getPendingVendorStart(state, action);
    case actionTypes.GET_PENDING_VENDOR_SUCCESS:
      return getPendingVendorSuccess(state, action);
    case actionTypes.GET_PENDING_VENDOR_FAILURE:
      return getPendingVendorFail(state, action);

    case actionTypes.GET_VENDOR_ADMIN_SIDE_VENDOR_PROFILE_DETAILS_START:
      return getVendorStart(state, action);
    case actionTypes.GET_VENDOR_ADMIN_SIDE_VENDOR_PROFILE_DETAILS_SUCCESS:
      return getVendorSuccess(state, action);
    case actionTypes.GET_VENDOR_ADMIN_SIDE_VENDOR_PROFILE_DETAILS_FAIL:
      return getVendorFail(state, action);

    case actionTypes.GET_VENDOR_ADMIN_TOKEN_LOADING:
      return getVendorAdminTokenLoading(state, action);
    case actionTypes.GET_VENDOR_ADMIN_TOKEN_SUCCESS:
      return getVendorAdminTokenSuccess(state, action);
    case actionTypes.GET_VENDOR_ADMIN_TOKEN_FAILURE:
      return getVendorAdminTokenFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
