import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";
import { resetPasswordLinkSuccess } from "../actions/auth";

const user = JSON.parse(localStorage.getItem("user")) || { token: null };

const initialState = {
  token: user.token,
  username: user.username,
  name: user.username,
  email: user.username,
  phone: user.phonenumber,
  is_admin: user.is_admin,
  is_consumer: user.is_consumer,
  is_vendor: user.is_vendor,
  userId: user.userId,
  error: false,
  loading: false,
  redirect: false,
  redirect_home: false,
  resetPasswordLinkSuccess: "",
  redirect_registration: false,
  ok: false,
  temp_vendor_id: null,
  is_authenticated:
    JSON.parse(localStorage.getItem("is_authenticated")) || false,
  pendingVendorId: user?.pendingVendorId || null,
  first_login: user?.first_login || false,
};

const authStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.user.token,
    username: action.user.username,
    is_admin: action.user.is_admin,
    is_consumer: action.user.is_consumer,
    is_vendor: action.user.is_vendor,
    userId: action.user.userId,
    error: null,
    loading: false,
    redirect: true,
    redirect_home: false,
    is_authenticated: true,
    pendingVendorId: action.user?.pendingVendorId || null,
    first_login: action.user?.first_login,
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: true,
    loading: false,
    is_authenticated: false,
  });
};

const authLogout = (state, action) => {
  return updateObject(state, {
    token: null,
    username: null,
    is_admin: null,
    is_consumer: null,
    is_vendor: null,
    userId: null,
    error: false,
    loading: false,
    redirect: false,
    redirect_home: true,
    redirect_registration: false,
    temp_vendor_id: null,
    is_authenticated: false,
    pendingVendorId: null,
  });
};

const resetPasswordLink = (state, action) => {
  return updateObject(state, {
    resetPasswordLinkSuccess: action.detail,
  });
};

const handleClearResetResponse = (state, action) => {
  return updateObject(state, {
    resetPasswordLinkSuccess: "",
  });
};

const signUpStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
    ok: false,
  });
};

const signUpSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    ok: true,
    redirect_registration: action.payload.redirect,
    temp_vendor_id: action.payload.vendor_id,
  });
};

const signUpFailure = (state, action) => {
  return updateObject(state, {
    ok: false,
    loading: false,
    error: action.payload.message,
  });
};

const updateUserDetails = (state, action) => {
  return updateObject(state, {
    name: action.userName,
    email: action.email,
    phone: action.phoneNumber,
  });
};

const pendingRegistration = (state, action) => {
  return updateObject(state, {
    redirect_registration: action.pendingRegistration,
    temp_vendor_id: action.vendorId,
  });
};

const setVendorAdminToken = (state, action) => {
  return updateObject(state, {
    token: action.token,
  });
};

const resetFirstLogin = (state, action) => {
  return updateObject(state, {
    first_login: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.RESET_PASSWORD_LINK:
      return resetPasswordLink(state, action);
    case actionTypes.CLEAR_RESET_RESPONSE:
      return handleClearResetResponse(state, action);
    case actionTypes.SIGN_UP_START:
      return signUpStart(state, action);
    case actionTypes.SIGN_UP_SUCCESS:
      return signUpSuccess(state, action);
    case actionTypes.SIGN_UP_FAILURE:
      return signUpFailure(state, action);
    case actionTypes.UPDATE_USER_DETAILS:
      return updateUserDetails(state, action);
    case actionTypes.PENDING_REGISTRATION:
      return pendingRegistration(state, action);
    case actionTypes.GET_VENDOR_ADMIN_TOKEN_SUCCESS:
      return setVendorAdminToken(state, action);
    case actionTypes.RESET_FIRST_LOGIN:
      return resetFirstLogin(state, action);
    default:
      return state;
  }
};

export default reducer;
