import React from "react";

const EcomReRoute = (props) => {
  const userType = props?.location?.state?.userType;

  const path = "/";
  let url = `${
    process.env.REACT_APP_ECOM_SITE_BASE_URL
  }?ecomToken=${localStorage.getItem(
    "ecomToken"
  )}&path=${path}&is_new=${localStorage.getItem(
    "is_new_ecom_user"
  )}&user_type=${userType}&redirectFrom=auction`;

  return window.location.replace(url);
};

export default EcomReRoute;
