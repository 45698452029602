import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";
import React from "react";

const initialState = {
  co2: 10000,
  deployed: 56,
  totalCapacity: 120,
  testimonials: [],
  projects: [],
  caseStudyList: [],
  error: null,
  loading: false,
  faq: [],
  update: false,
  rate: 0,
  total_saved: 0,
  total_auction: 0,
  ongoing_installation: 0,
  total_sites: 0,
  total_auction: 0,
  completed_installation: 0,
  total_installation: 0,
  total_epc_size: 0,
  platform: {
    capacity: 0,
    total_auctions: 0,
    closure_rate: 0,
    project_opportunity_created: 200,
    project_funded: 80,
    vendor_epc_companies_partners: 350,
    vendor_project_defaults: 0,
  },
  landing_page_vendor_header: "Solar Solution Providers",
  landing_page_vendor_description:
    "Grow your solar installation business and execute better quality projects, more efficiently",
  landing_page_vendor_cta: "Grow My Business",
  landing_page_customer_header: "Buy Solar",
  landing_page_customer_description:
    "Buy the best quality solar plants, at the right price, without any errors or hassles",
  landing_page_customer_cta: "Switch to Clean Energy",
  carbon_emission_text: (
    <>
      Tonnes of CO<sub>2</sub> Emmissions Saved
    </>
  ),
  vendor_screen_1_emission_text: "of Business Generated for EPCs",
  vendor_screen_1_description:
    "The Smart way to switch to solar power is Here!",
  vendor_screen_1_cta: "Find Out How",
  vendor_screen_1_gallery_cta: "Link to gallery",
  vendor_screen_2_header: "How Does it Work?",
  vendor_screen_2_description:
    "A Process Designed to get you the best from the best!",
  vendor_screen_2_pre_sales_title_1: "Requirement Assessment",
  vendor_screen_2_pre_sales_title_2: "Online Bidding",
  vendor_screen_2_pre_sales_title_3: "Project Closure",
  vendor_screen_2_post_sales_title_1: "Project Planning and Tracking",
  vendor_screen_2_post_sales_title_2: "Quality Control and Handover",
  vendor_screen_2_post_sales_title_3: "Operations and Maintenance",
  vendor_screen_2_pre_sales_description_1: (
    <>
      Our team visits your facility.
      <br />
      We understand your desired outcomes, current infrastructure and
      technical constraints.
    </>
  ),
  vendor_screen_2_pre_sales_description_2: (
    <>
      Based on Project Specifications we recommend the best companies in
      the country for the project.
      <br />
      You select the companies that you are interested in.
    </>
  ),
  vendor_screen_2_pre_sales_description_3: (
    <>
      We help you understand the detailed techno commercial aspects of all
      the bids.
      <br />
      Contract Negotiations with the Finalized Vendor.
    </>
  ),
  vendor_screen_2_post_sales_description_1:
    "Kick off meeting between Yourself, SafEarth and EPC Company to plan the project execution.",
  vendor_screen_2_post_sales_description_2: (
    <>
      Checks and Balances at every step to ensure quality of the plant.
      <br />
      Detailed quality inspections held by SafEarth at different stages.
    </>
  ),
  vendor_screen_2_post_sales_description_3: (
    <>
      Easily keep a track on expected performance and actual performance.
      <br />
      Automatic tracking and set up of required O&M Activities.
    </>
  ),
  vendor_screen_3_header: "Sell Solar Plants",
  vendor_screen_3_description:
    "Our online marketplace makes buying a solar plant for your\
    business or home as easy as buying a phone online.",
  vendor_screen_3_cta: "View Our Projects",
  vendor_screen_3_section_title_1: "Reduce Time",
  vendor_screen_3_section_title_2: "Reduce Cost",
  vendor_screen_3_section_title_3: "Easy Buying",
  vendor_screen_3_section_title_4: "Best Quality",
  vendor_screen_3_section_description_1: "40% to 50% reduction in time",
  vendor_screen_3_section_description_2: "10% to 20% reduction in cost",
  vendor_screen_3_section_description_3: "As easy as buying a phone online",
  vendor_screen_3_section_description_4: "Ensuring highest quality standards",
  vendor_screen_4_header: "Automate Your Plant Installation",
  vendor_screen_4_description:
    "Manage your solar plant installation on the go.",
  vendor_screen_4_section_title_1: "On Time Delivery",
  vendor_screen_4_section_title_2: "Reduce Costs",
  vendor_screen_4_section_title_3: "Ease of Management",
  vendor_screen_4_section_title_4: "Customer Experience",
  vendor_screen_4_section_description_1:
    "Get customized alerts whenever your project is going off course.\
    Monitor the schedule and be two steps ahead the entire time.\
    We automate a number of time consuming work that engage\
    your team throughout the project.",
  vendor_screen_4_section_description_2:
    "Solar flow helps in streamlining your project expenses by reducing time, errors and cost over runs to increase your overall profitability.",
  vendor_screen_4_section_description_3:
    "All your project related information is now on your fingertips so that you can manage your project on the go.",
  vendor_screen_4_section_description_4:
    "Ensure that your customer is updated about the project in real time and they get the best possible experience when transitioning to solar with you.",
  vendor_screen_5_header: "Important Figures",
  vendor_screen_5_description:
    "Our online marketplace for solar components helps EPC companies reduce their procurement cost and provide a better deal to you",
  vendor_screen_5_cta: "Become a Vendor",
  vendor_screen_5_cta_header_title: "Grow your Business with SafEarth",
  vendor_screen_5_imp_figures_center_title: (
    <>
      Annual CO<sub>2</sub> Reduction
    </>
  ),
  vendor_screen_5_imp_figures_center_value: "175,000",
  vendor_screen_5_imp_figures_1_title: "No.of States Served",
  vendor_screen_5_imp_figures_1_value: 20,
  vendor_screen_5_imp_figures_2_title: "Total Installed Capacity",
  vendor_screen_5_imp_figures_2_value: 50,
  vendor_screen_5_imp_figures_3_title: "Component Sellers",
  vendor_screen_5_imp_figures_3_value: 50,
  vendor_screen_5_imp_figures_4_title: "Project Developers",
  vendor_screen_5_imp_figures_4_value: 50,
  vendor_screen_5_imp_figures_5_title: "Capacity Under Deployment",
  vendor_screen_5_imp_figures_5_value: 50,
  vendor_screen_5_imp_figures_6_title: "Verified EPCs",
  vendor_screen_5_imp_figures_6_value: 350,
  customer_screen_1_emission_text: "of Solar Installed",
  customer_screen_1_description:
    "The Smart way to switch to solar power is Here!",
  customer_screen_1_cta: "Find Out How",
  customer_screen_1_gallery_cta: "Link to gallery",
  customer_screen_2_header: "How Does it Work?",
  customer_screen_2_description:
    "A Process Designed to get you the best from the best!",
  customer_screen_2_pre_sales_title_1: "Requirement Assessment",
  customer_screen_2_pre_sales_title_2: "Online Bidding",
  customer_screen_2_pre_sales_title_3: "Project Closure",
  customer_screen_2_post_sales_title_1: "Project Planning and Tracking",
  customer_screen_2_post_sales_title_2: "Quality Control and Handover",
  customer_screen_2_post_sales_title_3: "Operations and Maintenance",
  customer_screen_2_pre_sales_description_1:
    "Our team visits your facility. We understand your desired outcomes, \
    current infrastructure and technical constraints. \
    We prepare a Detailed Project Report covering all aspects of the project optimizing the solar plant for you.",
  customer_screen_2_pre_sales_description_2: (
    <>
      Based on Project Specifications we recommend the best companies in
      the country for the project.
      <br />
      You select the companies that you are interested in.
    </>
  ),
  customer_screen_2_pre_sales_description_3: (
    <>
      We help you understand the detailed techno commercial aspects of all
      the bids.
      <br />
      Contract Negotiations with the Finalized customer.
    </>
  ),
  customer_screen_2_post_sales_description_1:
    "Kick off meeting between Yourself, SafEarth and EPC Company to plan the project execution.",
  customer_screen_2_post_sales_description_2: (
    <>
      Checks and Balances at every step to ensure quality of the plant.
      <br />
      Detailed quality inspections held by SafEarth at different stages.
    </>
  ),
  customer_screen_2_post_sales_description_3: (
    <>
      Easily keep a track on expected performance and actual performance.
      <br />
      Automatic tracking and set up of required O&M Activities.
    </>
  ),
  customer_screen_3_header: "Buy Solar Plants",
  customer_screen_3_description:
    "Our online marketplace makes buying a solar plant for your\
  business or home as easy as buying a phone online.",
  customer_screen_3_cta: "View Our Projects",
  customer_screen_3_section_title_1: "Reduce Time",
  customer_screen_3_section_title_2: "Reduce Cost",
  customer_screen_3_section_title_3: "Easy Buying",
  customer_screen_3_section_title_4: "Best Quality",
  customer_screen_3_section_description_1: "40% to 50% reduction in time",
  customer_screen_3_section_description_2: "10% to 20% reduction in cost",
  customer_screen_3_section_description_3: "As easy as buying a phone online",
  customer_screen_3_section_description_4:
    "Ensuring highest quality standards",
  customer_screen_4_header: "Automate Your Plant Installation",
  customer_screen_4_description:
    "Manage your solar plant installation on the go.",
  customer_screen_4_section_title_1: "On Time Delivery",
  customer_screen_4_section_title_2: "Reduce Costs",
  customer_screen_4_section_title_3: "Ease of Management",
  customer_screen_4_section_title_4: "Customer Experience",
  customer_screen_4_section_description_1:
    "Get customized alerts whenever your project is going off course.\
    Monitor the schedule and be two steps ahead the entire time.\
    We automate a number of time consuming work that engage\
    your team throughout the project.",
  customer_screen_4_section_description_2:
    "Solar flow helps in streamlining your project expenses by reducing time, errors and cost over runs to increase your overall profitability.",
  customer_screen_4_section_description_3:
    "All your project related information is now on your fingertips so that you can manage your project on the go.",
  customer_screen_4_section_description_4:
    "Ensure that your customer is updated about the project in real time and they get the best possible experience when transitioning to solar with you.",
  customer_screen_5_header: "Important Figures",
  customer_screen_5_description:
    "Our online marketplace for solar components helps EPC companies reduce their procurement cost and provide a better deal to you",
  customer_screen_5_cta: "Buy Solar Now",
  customer_screen_5_cta_header_title: "Electricity Savings Just a Click Away",
  customer_screen_5_imp_figures_center_title: (
    <>
      Annual CO<sub>2</sub> Reduction
    </>
  ),
  customer_screen_5_imp_figures_center_value: "175,000",
  customer_screen_5_imp_figures_1_title: "No.of States Served",
  customer_screen_5_imp_figures_1_value: 20,
  customer_screen_5_imp_figures_2_title: "Total Installed Capacity",
  customer_screen_5_imp_figures_2_value: 50,
  customer_screen_5_imp_figures_3_title: "Component Sellers",
  customer_screen_5_imp_figures_3_value: 50,
  customer_screen_5_imp_figures_4_title: "Project Developers",
  customer_screen_5_imp_figures_4_value: 50,
  customer_screen_5_imp_figures_5_title: "Capacity Under Deployment",
  customer_screen_5_imp_figures_5_value: 50,
  customer_screen_5_imp_figures_6_title: "Verified EPCs",
  customer_screen_5_imp_figures_6_value: 350,
};

const getHomeDetailsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getHomeDetailsSuccess = (state = initialState, action) => {
  const dynamicTextData = action.details.platform;

  return updateObject(state, {
    co2: action.details.platform.co2,
    deployed: action.details.platform.deployment,
    totalCapacity: action.details.platform.capacity,
    testimonials: action.details.testimonials,
    projects: action.details.projects,
    faq: action.details.faq,
    caseStudyList: action.details.case_study_list,
    error: null,
    loading: false,
    total_installation: action.details.total_installation,
    completed_installation: action.details.completed_installation,
    ongoing_installation: action.details.ongoing_installation,
    total_sites: action.details.total_sites,
    total_auction: action.details.total_auction,
    closure_rate: action.details.platform.closure_rate,
    total_epc_size: action.details.total_epc_size,
    platform: {
      ...action.details.platform,
      project_opportunity_created:
        action.details.platform.project_opportunity_created ||
        state.platform.project_opportunity_created,
      project_funded:
        action.details.platform.project_funded || state.platform.project_funded,
      vendor_epc_companies_partners:
        action.details.platform.vendor_epc_companies_partners ||
        state.platform.vendor_epc_companies_partners,
      vendor_project_defaults:
        action.details.platform.vendor_project_defaults ||
        state.platform.vendor_project_defaults,
    },
    landing_page_vendor_header:
      dynamicTextData.landing_page_vendor_header || "Solar Solution Providers",
    landing_page_vendor_description:
      dynamicTextData.landing_page_vendor_description ||
      "Grow your solar installation business and execute better quality projects, more efficiently",
    landing_page_vendor_cta:
      dynamicTextData.landing_page_vendor_cta || "Grow My Business",
    landing_page_customer_header:
      dynamicTextData.landing_page_customer_header || "Buy Solar",
    landing_page_customer_description:
      dynamicTextData.landing_page_customer_description ||
      "Buy the best quality solar plants, at the right price, without any errors or hassles",
    landing_page_customer_cta:
      dynamicTextData.landing_page_customer_cta ||
      "Switch to Clean Energy",
    carbon_emission_text: dynamicTextData.carbon_emission_text || (
      <>
        Tonnes of CO<sub>2</sub> Emmissions Saved
      </>
    ),
    vendor_screen_1_emission_text:
      dynamicTextData.vendor_screen_1_emission_text ||
      "of Business Generated for EPCs",
    vendor_screen_1_description:
      dynamicTextData.vendor_screen_1_description ||
      "The Smart way to switch to solar power is Here!",
    vendor_screen_1_cta:
      dynamicTextData.vendor_screen_1_cta || "Find Out How",
    vendor_screen_1_gallery_cta:
      dynamicTextData.vendor_screen_1_gallery_cta || "Link to gallery",
    vendor_screen_2_header:
      dynamicTextData.vendor_screen_2_header || "How Does it Work?",
    vendor_screen_2_description:
      dynamicTextData.vendor_screen_2_description ||
      "A Process Designed to get you the best from the best!",
    vendor_screen_2_pre_sales_title_1:
      dynamicTextData.vendor_screen_2_pre_sales_title_1 ||
      "Requirement Assessment",
    vendor_screen_2_pre_sales_title_2:
      dynamicTextData.vendor_screen_2_pre_sales_title_2 ||
      "Online Bidding",
    vendor_screen_2_pre_sales_title_3:
      dynamicTextData.vendor_screen_2_pre_sales_title_3 ||
      "Project Closure",
    vendor_screen_2_post_sales_title_1:
      dynamicTextData.vendor_screen_2_post_sales_title_1 ||
      "Project Planning and Tracking",
    vendor_screen_2_post_sales_title_2:
      dynamicTextData.vendor_screen_2_post_sales_title_2 ||
      "Quality Control and Handover",
    vendor_screen_2_post_sales_title_3:
      dynamicTextData.vendor_screen_2_post_sales_title_3 ||
      "Operations and Maintenance",
    vendor_screen_2_pre_sales_description_1:
      dynamicTextData.vendor_screen_2_pre_sales_description_1 || (
        <>
          Our team visits your facility.
          <br />
          We understand your desired outcomes, current infrastructure
          and technical constraints.
        </>
      ),
    vendor_screen_2_pre_sales_description_2:
      dynamicTextData.vendor_screen_2_pre_sales_description_2 || (
        <>
          Based on Project Specifications we recommend the best
          companies in the country for the project.
          <br />
          You select the companies that you are interested in.
        </>
      ),
    vendor_screen_2_pre_sales_description_3:
      dynamicTextData.vendor_screen_2_pre_sales_description_3 || (
        <>
          We help you understand the detailed techno commercial
          aspects of all the bids.
          <br />
          Contract Negotiations with the Finalized Vendor.
        </>
      ),
    vendor_screen_2_post_sales_description_1:
      dynamicTextData.vendor_screen_2_post_sales_description_1 ||
      "Kick off meeting between Yourself, SafEarth and EPC Company to plan the project execution.",
    vendor_screen_2_post_sales_description_2:
      dynamicTextData.vendor_screen_2_post_sales_description_2 || (
        <>
          Checks and Balances at every step to ensure quality of the
          plant.
          <br />
          Detailed quality inspections held by SafEarth at different
          stages.
        </>
      ),
    vendor_screen_2_post_sales_description_3:
      dynamicTextData.vendor_screen_2_post_sales_description_3 || (
        <>
          Easily keep a track on expected performance and actual
          performance.
          <br />
          Automatic tracking and set up of required O&M Activities.
        </>
      ),
    vendor_screen_3_header:
      dynamicTextData.vendor_screen_3_header || "Sell Solar Plants",
    vendor_screen_3_description:
      dynamicTextData.vendor_screen_3_description ||
      "Our online marketplace makes buying a solar plant for your\
      business or home as easy as buying a phone online.",
    vendor_screen_3_cta:
      dynamicTextData.vendor_screen_3_cta || "View Our Projects",
    vendor_screen_3_section_title_1:
      dynamicTextData.vendor_screen_3_section_title_1 || "Reduce Time",
    vendor_screen_3_section_title_2:
      dynamicTextData.vendor_screen_3_section_title_2 || "Reduce Cost",
    vendor_screen_3_section_title_3:
      dynamicTextData.vendor_screen_3_section_title_3 || "Easy Buying",
    vendor_screen_3_section_title_4:
      dynamicTextData.vendor_screen_3_section_title_4 || "Best Quality",
    vendor_screen_3_section_description_1:
      dynamicTextData.vendor_screen_3_section_description_1 ||
      "40% to 50% reduction in time",
    vendor_screen_3_section_description_2:
      dynamicTextData.vendor_screen_3_section_description_2 ||
      "10% to 20% reduction in cost",
    vendor_screen_3_section_description_3:
      dynamicTextData.vendor_screen_3_section_description_3 ||
      "As easy as buying a phone online",
    vendor_screen_3_section_description_4:
      dynamicTextData.vendor_screen_3_section_description_4 ||
      "Ensuring highest quality standards",
    vendor_screen_4_header:
      dynamicTextData.vendor_screen_4_header ||
      "Automate Your Plant Installation",
    vendor_screen_4_description:
      dynamicTextData.vendor_screen_4_description ||
      "Manage your solar plant installation on the go.",
    vendor_screen_4_section_title_1:
      dynamicTextData.vendor_screen_4_section_title_1 ||
      "On Time Delivery",
    vendor_screen_4_section_title_2:
      dynamicTextData.vendor_screen_4_section_title_2 || "Reduce Costs",
    vendor_screen_4_section_title_3:
      dynamicTextData.vendor_screen_4_section_title_3 ||
      "Ease of Management",
    vendor_screen_4_section_title_4:
      dynamicTextData.vendor_screen_4_section_title_4 ||
      "Customer Experience",
    vendor_screen_4_section_description_1:
      dynamicTextData.vendor_screen_4_section_description_1 ||
      "Get customized alerts whenever your project is going off course.\
      Monitor the schedule and be two steps ahead the entire time.\
      We automate a number of time consuming work that engage\
      your team throughout the project.",
    vendor_screen_4_section_description_2:
      dynamicTextData.vendor_screen_4_section_description_2 ||
      "Solar flow helps in streamlining your project expenses by reducing time, errors and cost over runs to increase your overall profitability.",
    vendor_screen_4_section_description_3:
      dynamicTextData.vendor_screen_4_section_description_3 ||
      "All your project related information is now on your fingertips so that you can manage your project on the go.",
    vendor_screen_4_section_description_4:
      dynamicTextData.vendor_screen_4_section_description_4 ||
      "Ensure that your customer is updated about the project in real time and they get the best possible experience when transitioning to solar with you.",
    vendor_screen_5_header:
      dynamicTextData.vendor_screen_5_header || "Important Figures",
    vendor_screen_5_description:
      dynamicTextData.vendor_screen_5_description ||
      "Our online marketplace for solar components helps EPC companies reduce their procurement cost and provide a better deal to you",
    vendor_screen_5_cta:
      dynamicTextData.vendor_screen_5_cta || "Become a Vendor",
    vendor_screen_5_cta_header_title:
      dynamicTextData.vendor_screen_5_cta_header_title ||
      "Grow your Business with SafEarth",
    vendor_screen_5_imp_figures_center_title:
      dynamicTextData.vendor_screen_5_imp_figures_center_title || (
        <>
          Annual CO<sub>2</sub> Reduction
        </>
      ),
    vendor_screen_5_imp_figures_center_value:
      dynamicTextData.vendor_screen_5_imp_figures_center_value ||
      // "175,000",
      state.total_saved,
    vendor_screen_5_imp_figures_1_title:
      dynamicTextData.vendor_screen_5_imp_figures_1_title ||
      "No.of States Served",
    vendor_screen_5_imp_figures_1_value:
      dynamicTextData.vendor_screen_5_imp_figures_1_value || state.vendor_screen_5_imp_figures_1_value,
    vendor_screen_5_imp_figures_2_title:
      dynamicTextData.vendor_screen_5_imp_figures_2_title ||
      "Total Installed Capacity",
    vendor_screen_5_imp_figures_2_value:
      dynamicTextData.vendor_screen_5_imp_figures_2_value || action.details.completed_installation,
    vendor_screen_5_imp_figures_3_title:
      dynamicTextData.vendor_screen_5_imp_figures_3_title ||
      "Component Sellers",
    vendor_screen_5_imp_figures_3_value:
      dynamicTextData.vendor_screen_5_imp_figures_3_value || state.vendor_screen_5_imp_figures_3_value,
    vendor_screen_5_imp_figures_4_title:
      dynamicTextData.vendor_screen_5_imp_figures_4_title ||
      "Project Developers",
    vendor_screen_5_imp_figures_4_value:
      dynamicTextData.vendor_screen_5_imp_figures_4_value || state.vendor_screen_5_imp_figures_4_value,
    vendor_screen_5_imp_figures_5_title:
      dynamicTextData.vendor_screen_5_imp_figures_5_title ||
      "Capacity Under Deployment",
    vendor_screen_5_imp_figures_5_value:
      dynamicTextData.vendor_screen_5_imp_figures_5_value || action.details.ongoing_installation,
    vendor_screen_5_imp_figures_6_title:
      dynamicTextData.vendor_screen_5_imp_figures_6_title ||
      "Verified EPCs",
    vendor_screen_5_imp_figures_6_value:
      dynamicTextData.vendor_screen_5_imp_figures_6_value || state.vendor_screen_5_imp_figures_6_value,
    customer_screen_1_emission_text:
      dynamicTextData.customer_screen_1_emission_text ||
      "of Solar Installed",
    customer_screen_1_description:
      dynamicTextData.customer_screen_1_description ||
      "The Smart way to switch to solar power is Here!",
    customer_screen_1_cta:
      dynamicTextData.customer_screen_1_cta || "Find Out How",
    customer_screen_1_gallery_cta:
      dynamicTextData.customer_screen_1_gallery_cta || "Link to gallery",
    customer_screen_2_header:
      dynamicTextData.customer_screen_2_header || "How Does it Work?",
    customer_screen_2_description:
      dynamicTextData.customer_screen_2_description ||
      "A Process Designed to get you the best from the best!",
    customer_screen_2_pre_sales_title_1:
      dynamicTextData.customer_screen_2_pre_sales_title_1 ||
      "Requirement Assessment",
    customer_screen_2_pre_sales_title_2:
      dynamicTextData.customer_screen_2_pre_sales_title_2 ||
      "Online Bidding",
    customer_screen_2_pre_sales_title_3:
      dynamicTextData.customer_screen_2_pre_sales_title_3 ||
      "Project Closure",
    customer_screen_2_post_sales_title_1:
      dynamicTextData.customer_screen_2_post_sales_title_1 ||
      "Project Planning and Tracking",
    customer_screen_2_post_sales_title_2:
      dynamicTextData.customer_screen_2_post_sales_title_2 ||
      "Quality Control and Handover",
    customer_screen_2_post_sales_title_3:
      dynamicTextData.customer_screen_2_post_sales_title_3 ||
      "Operations and Maintenance",
    customer_screen_2_pre_sales_description_1:
      dynamicTextData.customer_screen_2_pre_sales_description_1 ||
      "Our team visits your facility. We understand your desired outcomes, \
      current infrastructure and technical constraints. \
      We prepare a Detailed Project Report covering all aspects of the project optimizing the solar plant for you.",
    customer_screen_2_pre_sales_description_2:
      dynamicTextData.customer_screen_2_pre_sales_description_2 || (
        <>
          Based on Project Specifications we recommend the best
          companies in the country for the project.
          <br />
          You select the companies that you are interested in.
        </>
      ),
    customer_screen_2_pre_sales_description_3:
      dynamicTextData.customer_screen_2_pre_sales_description_3 || (
        <>
          We help you understand the detailed techno commercial
          aspects of all the bids.
          <br />
          Contract Negotiations with the Finalized customer.
        </>
      ),
    customer_screen_2_post_sales_description_1:
      dynamicTextData.customer_screen_2_post_sales_description_1 ||
      "Kick off meeting between Yourself, SafEarth and EPC Company to plan the project execution.",
    customer_screen_2_post_sales_description_2:
      dynamicTextData.customer_screen_2_post_sales_description_2 || (
        <>
          Checks and Balances at every step to ensure quality of the
          plant.
          <br />
          Detailed quality inspections held by SafEarth at different
          stages.
        </>
      ),
    customer_screen_2_post_sales_description_3:
      dynamicTextData.customer_screen_2_post_sales_description_3 || (
        <>
          Easily keep a track on expected performance and actual
          performance.
          <br />
          Automatic tracking and set up of required O&M Activities.
        </>
      ),
    customer_screen_3_header:
      dynamicTextData.customer_screen_3_header || "Buy Solar Plants",
    customer_screen_3_description:
      dynamicTextData.customer_screen_3_description ||
      "Our online marketplace makes buying a solar plant for your\
    business or home as easy as buying a phone online.",
    customer_screen_3_cta:
      dynamicTextData.customer_screen_3_cta || "View Our Projects",
    customer_screen_3_section_title_1:
      dynamicTextData.customer_screen_3_section_title_1 || "Reduce Time",
    customer_screen_3_section_title_2:
      dynamicTextData.customer_screen_3_section_title_2 || "Reduce Cost",
    customer_screen_3_section_title_3:
      dynamicTextData.customer_screen_3_section_title_3 || "Easy Buying",
    customer_screen_3_section_title_4:
      dynamicTextData.customer_screen_3_section_title_4 || "Best Quality",
    customer_screen_3_section_description_1:
      dynamicTextData.customer_screen_3_section_description_1 ||
      "40% to 50% reduction in time",
    customer_screen_3_section_description_2:
      dynamicTextData.customer_screen_3_section_description_2 ||
      "10% to 20% reduction in cost",
    customer_screen_3_section_description_3:
      dynamicTextData.customer_screen_3_section_description_3 ||
      "As easy as buying a phone online",
    customer_screen_3_section_description_4:
      dynamicTextData.customer_screen_3_section_description_4 ||
      "Ensuring highest quality standards",
    customer_screen_4_header:
      dynamicTextData.customer_screen_4_header ||
      "Automate Your Plant Installation",
    customer_screen_4_description:
      dynamicTextData.customer_screen_4_description ||
      "Manage your solar plant installation on the go.",
    customer_screen_4_section_title_1:
      dynamicTextData.customer_screen_4_section_title_1 ||
      "On Time Delivery",
    customer_screen_4_section_title_2:
      dynamicTextData.customer_screen_4_section_title_2 || "Reduce Costs",
    customer_screen_4_section_title_3:
      dynamicTextData.customer_screen_4_section_title_3 ||
      "Ease of Management",
    customer_screen_4_section_title_4:
      dynamicTextData.customer_screen_4_section_title_4 ||
      "Customer Experience",
    customer_screen_4_section_description_1:
      dynamicTextData.customer_screen_4_section_description_1 ||
      "Get customized alerts whenever your project is going off course.\
      Monitor the schedule and be two steps ahead the entire time.\
      We automate a number of time consuming work that engage\
      your team throughout the project.",
    customer_screen_4_section_description_2:
      dynamicTextData.customer_screen_4_section_description_2 ||
      "Solar flow helps in streamlining your project expenses by reducing time, errors and cost over runs to increase your overall profitability.",
    customer_screen_4_section_description_3:
      dynamicTextData.customer_screen_4_section_description_3 ||
      "All your project related information is now on your fingertips so that you can manage your project on the go.",
    customer_screen_4_section_description_4:
      dynamicTextData.customer_screen_4_section_description_4 ||
      "Ensure that your customer is updated about the project in real time and they get the best possible experience when transitioning to solar with you.",
    customer_screen_5_header:
      dynamicTextData.customer_screen_5_header || "Important Figures",
    customer_screen_5_description:
      dynamicTextData.customer_screen_5_description ||
      "Our online marketplace for solar components helps EPC companies reduce their procurement cost and provide a better deal to you",
    customer_screen_5_cta:
      dynamicTextData.customer_screen_5_cta || "Buy Solar Now",
    customer_screen_5_cta_header_title:
      dynamicTextData.customer_screen_5_cta_header_title ||
      "Electricity Savings Just a Click Away",
    customer_screen_5_imp_figures_center_title:
      dynamicTextData.customer_screen_5_imp_figures_center_title || (
        <>
          Annual CO<sub>2</sub> Reduction
        </>
      ),
    customer_screen_5_imp_figures_center_value:
      dynamicTextData.customer_screen_5_imp_figures_center_value ||
      // "175,000",
      state.total_saved,
    customer_screen_5_imp_figures_1_title:
      dynamicTextData.customer_screen_5_imp_figures_1_title ||
      "No.of States Served",
    customer_screen_5_imp_figures_1_value:
      dynamicTextData.customer_screen_5_imp_figures_1_value || state.customer_screen_5_imp_figures_1_value,
    customer_screen_5_imp_figures_2_title:
      dynamicTextData.customer_screen_5_imp_figures_2_title ||
      "Total Installed Capacity",
    customer_screen_5_imp_figures_2_value:
      dynamicTextData.customer_screen_5_imp_figures_2_value  || action.details.completed_installation,
    customer_screen_5_imp_figures_3_title:
      dynamicTextData.customer_screen_5_imp_figures_3_title ||
      "Component Sellers",
    customer_screen_5_imp_figures_3_value:
      dynamicTextData.customer_screen_5_imp_figures_3_value || state.customer_screen_5_imp_figures_3_value,
    customer_screen_5_imp_figures_4_title:
      dynamicTextData.customer_screen_5_imp_figures_4_title ||
      "Project Developers",
    customer_screen_5_imp_figures_4_value:
      dynamicTextData.customer_screen_5_imp_figures_4_value || state.customer_screen_5_imp_figures_4_value,
    customer_screen_5_imp_figures_5_title:
      dynamicTextData.customer_screen_5_imp_figures_5_title ||
      "Capacity Under Deployment",
    customer_screen_5_imp_figures_5_value:
      dynamicTextData.customer_screen_5_imp_figures_5_value || action.details.ongoing_installation,
    customer_screen_5_imp_figures_6_title:
      dynamicTextData.customer_screen_5_imp_figures_6_title ||
      "Verified EPCs",
    customer_screen_5_imp_figures_6_value:
      dynamicTextData.customer_screen_5_imp_figures_6_value || state.customer_screen_5_imp_figures_6_value,
    safearth_news_list: action.details.safearth_news_list,
  });
};

const getHomeDetailsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const getScorecardStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getScorecardSuccess = (state, action) => {
  return updateObject(state, {
    rate: action.details.rate,
    update: action.details.update,
    total_saved: action.details.total_saved,
    error: null,
    loading: false,
  });
};

const getScorecardFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_HOME_DETAIL_START:
      return getHomeDetailsStart(state, action);
    case actionTypes.GET_HOME_DETAIL_SUCCESS:
      return getHomeDetailsSuccess(state, action);
    case actionTypes.GET_HOME_DETAIL_FAIL:
      return getHomeDetailsFail(state, action);
    case actionTypes.GET_SCORECARD_START:
      return getScorecardStart(state, action);
    case actionTypes.GET_SCORECARD_FAIL:
      return getScorecardFail(state, action);
    case actionTypes.GET_SCORECARD_SUCCESS:
      return getScorecardSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
