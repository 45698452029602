import * as actionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  auction: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
  pmt: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
  ecom: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
};

const safearthDashboardReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_SAFEARTH_DASHBOARD_LOADING:
      return {
        ...state,
        [action.platform]: {
          ...state[action.platform],
          get: {
            ...state[action.platform].get,
            loading: true,
            reset: false,
            success: {
              ...state[action.platform].get.success,
              ok: false,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case actionTypes.GET_SAFEARTH_DASHBOARD_SUCCESS:
      return {
        ...state,
        [action.platform]: {
          ...state[action.platform],
          get: {
            ...state[action.platform].get,
            loading: false,
            reset: false,
            success: {
              ...state[action.platform].get.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case actionTypes.GET_SAFEARTH_DASHBOARD_FAILURE:
      return {
        ...state,
        [action.platform]: {
          ...state[action.platform],
          get: {
            ...state[action.platform].get,
            loading: false,
            reset: false,
            success: {
              ...state[action.platform].get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };
    case actionTypes.GET_SAFEARTH_DASHBOARD_RESET:
      return {
        ...state,
        [action.platform]: {
          ...state[action.platform].get,
          get: {
            ...INITIAL_STATE[action.platform].get,
            reset: true,
          },
        },
      };
  }
  return state;
};

export default safearthDashboardReducers;
