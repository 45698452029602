import * as Actions from "../../actions/actionTypes";

const INITIAL_STATE = {
  activeConsumerProjects: {
    get: {
      loading: false,
      reset: false,
      success: {
        ok: false,
        data: null,
      },
      failure: {
        error: false,
        message: "",
      },
    },
  },
};

const getProjectReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Actions.GET_ACTIVE_CONSUMER_PROJECTS_FROM_SOLARFLOW_LOADING:
      return {
        ...state,
        activeConsumerProjects: {
          ...state.activeConsumerProjects,
          get: {
            ...state.activeConsumerProjects.get,
            loading: true,
            reset: false,
            success: {
              ...state.activeConsumerProjects.get.success,
              ok: false,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case Actions.GET_ACTIVE_CONSUMER_PROJECTS_FROM_SOLARFLOW_SUCCESS:
      return {
        ...state,
        activeConsumerProjects: {
          ...state.activeConsumerProjects,
          get: {
            ...state.activeConsumerProjects.get,
            loading: false,
            reset: false,
            success: {
              ...state.activeConsumerProjects.get.success,
              ok: true,
              data: action.payload,
            },
            failure: {
              error: false,
              message: "",
            },
          },
        },
      };
    case Actions.GET_ACTIVE_CONSUMER_PROJECTS_FROM_SOLARFLOW_FAILURE:
      return {
        ...state,
        activeConsumerProjects: {
          ...state.activeConsumerProjects,
          get: {
            ...state.activeConsumerProjects.get,
            loading: false,
            reset: false,
            success: {
              ...state.activeConsumerProjects.get.success,
              ok: false,
              data: null,
            },
            failure: {
              error: true,
              message: action.payload.message,
            },
          },
        },
      };
    case Actions.GET_ACTIVE_CONSUMER_PROJECTS_FROM_SOLARFLOW_RESET:
      return {
        ...state,
        activeConsumerProjects: {
          ...state.login.activeConsumerProjects,
          get: {
            ...INITIAL_STATE.activeConsumerProjects.get,
            reset: true,
          },
        },
      };
  }
  return state;
};

export default getProjectReducers;
